import axios from 'axios';
import { config } from '../Configurator'
import { buildCaseAssessorPayload, multiplyFields, convertDateForApi, convertToDouble, convertStringIfNumeric } from '../Utils'
import alertify from 'alertifyjs';

const endpoint = "CreateCaseJSON";
const endpoint2 = "GetCaseAssessorByCaseJSON";
const endpoint3 = "UpdateCaseAssessorJSON";

export const executeCreateCaseJSONwithID = (att, applicant_id) => {


    let payload_1 = createCasePayload(att, applicant_id);

    axios.post(config().base_url + endpoint, payload_1).then(function (response) {
        if(response.data.d.EnquiryResult.SuccessCode==0) {

            applicant_id = response.data.d.Value;

            axios.post(config().base_url + endpoint2, getCaseAssessorPayload(applicant_id)).then(function (response) {

                if(response.data.d.EnquiryResult.SuccessCode==0) {
                    // #3 if everything okay update case assessor 
                    axios.post(config().base_url + endpoint3, buildCaseAssessorPayload(att, applicant_id, response.data)).then(function (response) {
                        if(response.data.d.EnquiryResult.SuccessCode==0) {
                            window.location.href = "/case/" + applicant_id;
                        } else {
                            alertify.confirm('Error', response.data.d.EnquiryResult.Message, null, null);
                        }
                    }).catch(function (error){
                        alertify.confirm('Error', error.message, null, null);
                    });

                }
            }).catch(function (error){
                alertify.confirm('Error', error.message, null, null);
            });
            
        } else {
            alertify.confirm('Error', response.data.d.EnquiryResult.Message, null, null);

        }
    }).catch(function (error) {
        alertify.confirm('Error', error.message, null, null);
    });

}


const createCasePayload = (att, applicant_id, customer_reference) => {

    return {

        "UserInfo": {
            "Username": localStorage.getItem('bo_email'),
            "Password": localStorage.getItem('bo_password')
        },
        "CaseType": "LoansMort",
        "CaseParams": {
            "Params": {
                "customerCode": applicant_id,
                "matterType": att.matterType,
                "provider": att.provider,
                "providerProduct": att.providerProduct,
                "LoanType": att.LoanType,
                "productCode": att.productCode,
                "providerRef": null,
                "totalLoan": att.totalLoan ?? 0,
                "Term": att.terms,
                "termPeriod": "M",
                "InterestMonthly": att.InterestMonthly ?? 0,
                "UnderWriter": att.UnderWriter,
                "Processor": att.Processor,
                "UserID": null,
                "AdditionalUser1": att.AdditionalUser1,
                "AdditionalUser2": "",
                "Team": "",
                "CaseAssignmentBranch": att.CaseAssignmentBranch,
                "source": "",
                "SourceEmail": "",
                "IsSourceNeedToCreate": "false",
                "SourceAddress": {
                    "houseName": "",
                    "houseNumber": null,
                    "address1": "",
                    "address2": "",
                    "address3": "",
                    "address4": "",
                    "postcode": ""
                },
                "sourceref": "",
                "moneyfor": "",
                "sourceAffiliate": null,
                "sourceInitialFeePerc": 0,
                "sourceInitialFee": 0,
                "affiliateInitialFeePerc": 0,
                "affiliateInitialFee": 0,
                "ExitFeePerc": null,
                "ExitFee": att.ExitFee ?? 0,
                "InitialFeePerc": null,
                "InitialFee": att.InitialFee ?? 0,
                "caseStatus": att.caseStatus,
                "currentAddressYears": "",
                "currentAddressMonths": null,
                "app1PreviousAddress": [{
                    "houseName": "",
                    "houseNumber": null,
                    "address1": "",
                    "address2": "",
                    "address3": "",
                    "address4": "",
                    "postcode": "",
                    "timeInYears": null,
                    "timeInMonths": null
                }],
                "DynamicFieldDetail": [{
                    "FieldNo": 1,
                    "FieldValue": ""
                },
                {
                    "FieldNo": 2,
                    "FieldValue": att.dynamicData2
                },
                {
                    "FieldNo": 3,
                    "FieldValue": att.risk_rating
                },
                {
                    "FieldNo": 4,
                    "FieldValue": att.dynamicData4
                },
                {
                    "FieldNo": 5,
                    "FieldValue": att.dynamicData5
                },
                {
                    "FieldNo": 6,
                    "FieldValue": att.dynamicData6
                },
                {
                    "FieldNo": 8,
                    "FieldValue": ""
                },
                {
                    "FieldNo": 11,
                    "FieldValue": ""
                },
                {
                    "FieldNo": 12,
                    "FieldValue": ""
                }
                ],
                "SecurityAddress": multiplyFields(att, {
                    "houseName": 'att.asset1_house_name',
                    "houseNumber": 'att.asset1_house_no',
                    "address1": 'att.asset1_addr1',
                    "address2": 'att.asset1_addr2',
                    "address3": 'att.asset1_addr3',
                    "address4": 'att.asset1_addr4',
                    "postcode": 'att.asset1_postal',
                    "CurrentValue": 'att.asset1_CurrentValue',
                    "SecuirtyType": 'att.asset1_securityType',
                    "SecuirtyDetails": 'att.asset1_securityDetails',
                    "SecuirtyCharge": 'att.asset1_SecurityCharge',
                    "SecurityTitleNumber": 'att.asset1_TitleNumber'
                }),
                "customerDetails": {
                    "customerCode": applicant_id,
                    "B2B": att.B2B,
                    "IsUpdateCustomerDetail" : true,
                    "AlternativeSalutation": att.AlternativeSalutation,
                    "title": "",
                    "firstName": att.firstName,
                    "middleName": "",
                    "surname": att.surname,
                    "houseName": "",
                    "houseNumber": "",
                    "address1": att.business_address1,
                    "address2": att.business_address2,
                    "address3": att.business_address3,
                    "address4": att.business_address4,
                    "postcode": att.business_postcode,
                    "source": "",
                    "SourceEmail": "",
                    "IsSourceNeedToCreate": "false",
                    "SourceAddress": {
                        "houseName": "",
                        "houseNumber": null,
                        "address1": "",
                        "address2": "",
                        "address3": "",
                        "address4": "",
                        "postcode": ""
                    },
                    "CompanyAddress": {
                        "address1": att.business_address1,
                        "address2": att.business_address2,
                        "address3": att.business_address3,
                        "address4": att.business_address4,
                        "postcode": att.business_postcode
                    },
                    "MultiContacts": multiplyFields(att, {
                        "CustomFields": [{
                            "CustomFieldNo": 1,
                            "CustomFieldValue": 'att.mc1_CustomFieldValue1'
                        },
                        {
                            "CustomFieldNo": 2,
                            "CustomFieldValue": 'att.mc1_CustomFieldValue2'
                        },
                        {
                            "CustomFieldNo": 3,
                            "CustomFieldValue": 'att.mc1_CustomFieldValue3'
                        },
                        {
                            "CustomFieldNo": 6,
                            "CustomFieldValue": 'att.mc1_CustomFieldValue6'
                        }
                        ],
                        "Firstname": 'att.mc1_Firstname',
                        "Surname": 'att.mc1_Surname',
                        // "Guarantor": 'att.mc1_Guarantor',
                        "Address1": 'att.mc1_address1',
                        "Address2": 'att.mc1_address2',
                        "Address3": 'att.mc1_address3',
                        "Address4": 'att.mc1_address4',
                        "PostCode": 'att.mc1_postcode',
                        "DirectPhone": 'att.mc1_telephone',
                        "Mobile": 'att.mc1_mobile',
                        "Email": 'att.mc1_email',
                        "Position": 'att.mc1_position',
                        "DateOfBirth": 'att.mc1_DateOfBirth'
                    },

                    ),
                    "telephone": att.telephone,
                    "mobile": att.mobile,
                    "dateofBirth": convertDateForApi(""),
                    "company": att.Company,
                    "email": att.Email,
                    "loanAmount": att.loanAmount ?? 0,
                    "applicants": null,
                    "othername": "",
                    "ninumber": "",
                    "ContactMethod": [
                        ""
                    ],
                    "notes": "",
                    "ClientStatus": att.ClientStatus,
                    "IsDeduplicationReq": true,
                    "BankDetail": [{
                        "BankName": "",
                        "AccountName": "",
                        "AccountNumber": "",
                        "SortCode": ""
                    }]
                },
                "otherApplicantDetails": [
                    {
                        "applicantNumber": 1,
                        "title": null,
                        "firstName": "",
                        "middleName": null,
                        "surname": null,
                        "houseName": null,
                        "houseNumber": null,
                        "address1": null,
                        "address2": null,
                        "address3": null,
                        "address4": null,
                        "postcode": null,
                        "telephone": null,
                        "mobile": null,
                        "sex": null,
                        "dateofBirth": convertDateForApi(''),
                        "email": null,
                        "maritalStatus": null,
                        "currentAddressYears": null,
                        "currentAddressMonths": null,
                        "currentAddressEstimatedValue": null,
                        "currentAddressPurchasePrice": null,
                        "previousAddress": [
                            {
                                "houseName": null,
                                "houseNumber": null,
                                "address1": "",
                                "address2": "",
                                "address3": "",
                                "address4": "",
                                "postcode": "",
                                "timeInYears": null,
                                "timeInMonths": null
                            }
                        ],
                        "employmentDetails": {
                            "occupationType": null,
                            "employmentStatus": null,
                            "employerName": "",
                            "address1": "",
                            "address2": "",
                            "address3": "",
                            "address4": "",
                            "postcode": "",
                            "timeInYears": null,
                            "timeInMonths": null,
                            "firstIncome": null,
                            "secondIncome": null,
                            "otherIncomeDescription": null,
                            "partTimeHoursWorked": null
                        },
                        "previousEmploymentDetails": [
                            {
                                "occupationType": null,
                                "employmentStatus": null,
                                "employerName": "",
                                "address1": "",
                                "address2": "",
                                "address3": "",
                                "address4": "",
                                "postcode": "",
                                "timeInYears": null,
                                "timeInMonths": null,
                                "firstIncome": null,
                                "secondIncome": null,
                                "otherIncomeDescription": null,
                                "partTimeHoursWorked": null
                            }
                        ]
                    }
                ]
            }
        }

    }
}

const getCaseAssessorPayload = (applicant_id) => {
    return {
        "UserInfo": {
            "Username": localStorage.getItem('bo_email'),
            "Password": localStorage.getItem('bo_password')
                  },
                  "jobCode": applicant_id
    }
}

export default {
    executeCreateCaseJSONwithID
};
