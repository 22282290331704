import React, { Component } from 'react';
import { config } from '../Configurator'
import axios from 'axios';
import { convertDate } from '../Utils';


class creditSafeConsumerSearch extends Component {

    constructor(props) {
        super(props)
        this.state = {
            multicontacts: [],
            clicked: false,
            customer_reference: null,
            director_data: null,
            disable_credit_search: false,
            forms: config().forms,
        }
    }

    componentDidMount() {
    }

    loadData(data) {
        // fake response 
        // data = [
        //     { Address1: null, Address2: null, Address3: null, Address4: null, CustomFields: [], DateOfBirth: null, DirectPhone: null, Email: null, Firstname: "Michael", ForceDelete: null, Guarantor: null, IncludeInPIP: null, Mobile: null, Position: "Director", PostCode: null, Surname: "Devine" },
        //     { Address1: null, Address2: null, Address3: null, Address4: null, CustomFields: [], DateOfBirth: null, DirectPhone: null, Email: null, Firstname: "Michael", ForceDelete: null, Guarantor: null, IncludeInPIP: null, Mobile: null, Position: "Director", PostCode: null, Surname: "Devine" }        
        // ];
        this.setState({ 'multicontacts': data.CaseApplicant.MultiContacts, customer_reference: data.CaseApplicant});
    }

    loadConsumer(index) {
        let mc = this.state.multicontacts[index];
        axios.post(config().base_url + 'CreditSafeConsumerReport', {
            "pRequest": {
                "CustomerCode": this.state.customer_reference,
                "UseCustomData": "",
                "CustomerAdditionalNumber": "",
                "firstName": mc.Firstname,
                "lastName": mc.Surname,
                "street": mc.Address1, // stripped
                "houseNo": mc.Address2,  // stripped
                "city": mc.Address3,
                "postCode": mc.PostCode,
                "dateOfBirth": null
            },
            "UserInfo": {
                "Username": localStorage.getItem('bo_email'),
                "Password": localStorage.getItem('bo_password')
            }
        })
        .then(response => {

            if(response.data.d && response.data.d.Records) {
                // alert("HERE");
                // resolve(response.data.d.Records);
            } else {
                // reject("Error while getting the results")
            }
        })
        .catch(error => {
            // reject('an error occurred');
            console.log(error);
        });

    }

    consumerSearchClick() {
        this.setState({multicontacts: this.state.multicontacts, clicked: true});
    }

    render() {
        const { multicontacts, disable_credit_search, clicked } = this.state;
        return (
            <div>
                <button className="btn btn-outline-dark btn-block btn-sm my-2" disabled={disable_credit_search} onClick={this.consumerSearchClick.bind(this)}>
                    <span><img src="/images/loading.gif" height={16} /></span> Consumer Search
                </button>
                {
                    multicontacts && multicontacts.length && clicked ?
                    multicontacts.map((entry, index) => {
                        return <p className='m-0 text-main'><a href="javascript:void(0)" onClick={() => this.loadConsumer(index)}>{ entry.Firstname } { entry.Surname }</a></p>
                    }) : 
                    null
                }

            </div>
        )
    }

}

export default creditSafeConsumerSearch;