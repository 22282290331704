import axios from 'axios';
import { config } from './../Configurator'
import { buildCaseAssessorPayload, multiplyFields, convertDateForApi, convertToDouble } from './../Utils'
import alertify from 'alertifyjs';

const endpoint = "UpdateCase";
const endpoint2 = "GetCaseAssessorByCaseJSON";
const endpoint3 = "UpdateCaseAssessorJSON";
const endpoint4 = "UpdateCustomer";

export const executeUpdateCaseReducedSec = (att, applicant_id, customer_reference) => {

    if (applicant_id === null) {
        alertify.confirm("Error", "Applicant ID can't be null", null, null);
        return false;
    }

    // Update customer payload
    let payload_4 = updateCustomerPayload(att, applicant_id, customer_reference);
    axios.post(config().base_url + endpoint4, payload_4).then(function (response) {

        if (response.data.d.UpdateResult.SuccessCode == 0) {

            // #1 Update case data 
            let payload_1 = updateCasePayload(att, applicant_id, customer_reference);

            if (localStorage.getItem('bo_email') == 'srdjanstojkovic.api') {
                console.log('payload_1');
                console.log(payload_1);
                return false;
            }

            axios.post(config().base_url + endpoint, payload_1).then(function (response) {
                let value = '';
                if (response.data.d.UpdateResult.SuccessCode == 0) {

                    // #2 if update goes well, get case assessor 

                    value = response.data.d.Value;

                    axios.post(config().base_url + endpoint2, getCaseAssessorPayload(applicant_id)).then(function (response) {

                        if (response.data.d.EnquiryResult.SuccessCode == 0) {
                            // #3 if everything okay update case assessor 
                            axios.post(config().base_url + endpoint3, buildCaseAssessorPayload(att, applicant_id, response.data)).then(function (response) {
                                if (response.data.d.EnquiryResult.SuccessCode == 0) {
                                    if (att.form_id == '13' || att.form_id == '22') { // delay for sound to play 
                                        setTimeout(function () { window.location.href = "/case/" + applicant_id; }, 2800);
                                    } else {
                                        window.location.href = "/case/" + applicant_id;
                                    }
                                }
                            }).catch(function (error) {
                                alertify.confirm('Error', error.message, null, null);
                            });

                        } else {
                            alertify.confirm('Error', response.data.d.EnquiryResult.Message, null, null);

                        }
                    }).catch(function (error) {
                        alertify.confirm('Error', error.message, null, null);
                    });

                    // window.location.href = "/thanks/" + response.data.d.Value;
                } else {
                    alertify.confirm('Error', response.data.d.UpdateResult.Message, null, null);
                }
            }).catch(function (error) {
                alertify.confirm('Error', error.message, null, null);
            });

        } else {
            alertify.confirm('Error', response.data.d.UpdateResult.Message, null, null);
        }

    });






}

const updateCustomerPayload = (att, applicant_id, customer_reference) => {
    return {
        "UserInfo": {
            "Username": localStorage.getItem('bo_email'),
            "Password": localStorage.getItem('bo_password')
        },
        "Customer": {
            "CustomerReference": customer_reference,
            "Firstname": att.Firstname,
            "Surname": att.Surname,
            "Firstname2": att.mc1_Firstname__1,
            "Surname2": att.mc1_Surname__1,
            "CompanyName": att.CompanyName,
            "AlternateSalutation": att.AltSalutation,
            "Email": att.Email,
            "Telephone": att.Telephone,
            "Mobile": att.Mobile,
            "CustomerStatus": "Prospect",
            // "B2BCustomer": "true",
            "IsUpdateCaseCustomer": true,
            "DateOfBirth": convertDateForApi(att.mc1_DateOfBirth__0),
            "InvoiceTermName": "",
            "CustomerAddress": {
                "HouseName": "",
                "HouseNumber": "",
                "Address1": att.Address1,
                "Address2": att.Address2,
                "Address3": att.Address3,
                "Address4": att.Address4,
                "PostCode": att.Postcode
            },
            "CompanyAddress": {
                "address1": att.compAddress1,
                "address2": att.compAddress2,
                "address3": att.compAddress3,
                "address4": att.compAddress4,
                "postcode": att.compPostcode
            },
            "MultiContacts": multiplyFields(att, {
                "CustomFields": [{
                    "CustomFieldNo": 1,
                    "CustomFieldValue": 'att.mc1_CustomFieldValue1'
                },
                {
                    "CustomFieldNo": 2,
                    "CustomFieldValue": 'att.mc1_CustomFieldValue2'
                },
                {
                    "CustomFieldNo": 3,
                    "CustomFieldValue": 'att.mc1_CustomFieldValue3'
                },
                {
                    "CustomFieldNo": 6,
                    "CustomFieldValue": 'att.mc1_CustomFieldValue6'
                }
                ],
                "Firstname": 'att.mc1_Firstname',
                "Surname": 'att.mc1_Surname',
                "Guarantor": 'att.Guarantors',
                "IncludeInPIP": 'att.mc1_IncludeInPIP',
                "Address1": 'att.mc1_address1',
                "Address2": 'att.mc1_address2',
                "Address3": 'att.mc1_address3',
                "Address4": 'att.mc1_address4',
                "PostCode": 'att.mc1_postcode',
                "DirectPhone": 'att.mc1_telephone',
                "Mobile": 'att.mc1_mobile',
                "Email": 'att.mc1_email',
                "ForceDelete": 'att.mc1_ForceDelete',
                "Position": 'att.mc1_position',
                "DateOfBirth": 'att.mc1_DateOfBirth'
            },

            )
        }
    }
}

const updateCasePayload = (att, applicant_id, customer_reference) => {

    let multiContacts = multiplyFields(att, {
        "CustomFields": [{
            "CustomFieldNo": 1,
            "CustomFieldValue": 'att.mc1_CustomFieldValue1'
        },
        {
            "CustomFieldNo": 2,
            "CustomFieldValue": 'att.mc1_CustomFieldValue2'
        },
        {
            "CustomFieldNo": 3,
            "CustomFieldValue": 'att.mc1_CustomFieldValue3'
        },
        {
            "CustomFieldNo": 6,
            "CustomFieldValue": 'att.mc1_CustomFieldValue6'
        }
        ],
        "Firstname": 'att.mc1_Firstname',
        "Surname": 'att.mc1_Surname',
        "Guarantor": 'att.mc1_Guarantors',
        "Address1": 'att.mc1_address1',
        "Address2": 'att.mc1_address2',
        "Address3": 'att.mc1_address3',
        "Address4": 'att.mc1_address4',
        "PostCode": 'att.mc1_postcode',
        "DirectPhone": 'att.mc1_telephone',
        "ForceDelete": 'att.mc1_ForceDelete',
        "Mobile": 'att.mc1_mobile',
        "Email": 'att.mc1_email',
        "Position": 'att.mc1_position',
        "DateOfBirth": 'att.mc1_DateOfBirth'
    });

    // update Guarantor to true if form is 14
    if (att.form_id == 14) {
        for (let i = 0; i < multiContacts.length; i++) {
            multiContacts[i]['Guarantor'] = true;
        }
    }

    let ret = {
        "UserInfo": {
            "Username": localStorage.getItem('bo_email'),
            "Password": localStorage.getItem('bo_password')
        },
        "CaseApp": {
            "ApplicantID": applicant_id,
            "CustomerReference": customer_reference,
            "AvoidUpdatingBlankData": true,
            "IsUpdateCaseCustomer": true,
            "MainApplicant": {
                "Title": '',
                "Firstname": att.FirstName,
                "Surname": att.Surname,
                "DateOfBirth": convertDateForApi(""),
                "Telephone": att.Telephone,
                "WorkTelephone": '',
                "Mobile": att.Mobile,
                "EmailAddress": att.Email,
                "CurrentAddress": {
                    "HouseName": '',
                    "HouseNumber": '',
                    "Address1": att.Address1,
                    "Address2": att.Address2,
                    "Address3": att.Address3,
                    "Address4": att.Address4,
                    "PostCode": att.Postcode
                },
                "PreviousAddresses": {},
                "App2ID": 0
            },
            "MatterType": '',
            "Provider": '',
            "ProviderDescription": '',
            "ProviderRef": '',
            "ProviderCaseRef": '',
            "OldCaseRef": applicant_id,
            "CreditType": '',
            "NetClaim": convertToDouble(att.NetClaim) ?? 0,
            "Term": att.Term,
            "InterestMonthly": att.InterestMonthly ?? 0,
            "Commission": convertToDouble(att.Commission) ?? 0,
            "ClaimInterest": att.ClaimInterest ?? 0,
            "CustomerInitialFee": convertToDouble(att.CustomerInitialFee) ?? 0,
            "CustomerCompletionFeePerc": att.CustomerCompletionFeePerc,
            "CustomerCompletionFee": convertToDouble(att.CustomerCompletionFee) ?? 0,
            "CaseStatus": att.CaseStatus,
            "CompanyAddress": {
                "address1": att.compAddress1,
                "address2": att.compAddress2,
                "address3": att.compAddress3,
                "address4": att.compAddress4,
                "postcode": att.compPostcode
            },
            "MultiContacts": multiContacts,
            "StatusDate": convertDateForApi(""),
            "Source": att.Source,
            "SourceRef": '',
            "SourceAffiliate": '',
            "SourceRef": att.SourceRef,
            "SourceInitialFee": convertToDouble(att.SourceInitialFee),
            "SourceCommissionPerc": att.SourceCommissionPerc,
            "DateCreated": convertDateForApi(""),
            //"UserCreated": null,
            "WorkflowStep": '',
            //"DaysOld": null,
            "CaseOwner": '',
            "CustomerOwner": '',
            "BrokerCode": '',
            "BrokerName": '',
            "BrokerReference": '',

            // 


            "DynamicFieldDetail": [{
                "FieldNo": 1,
                "FieldValue": ""
            },
            {
                "FieldNo": 2,
                "FieldValue": att.dynamicData2
            },
            {
                "FieldNo": 4,
                "FieldValue": att.dynamicData4
            },
            {
                "FieldNo": 5,
                "FieldValue": att.dynamicData5
            },
            {
                "FieldNo": 6,
                "FieldValue": att.dynamicData6
            },
            {
                "FieldNo": 8,
                "FieldValue": ""
            },
            {
                "FieldNo": 11,
                "FieldValue": ""
            },
            {
                "FieldNo": 12,
                "FieldValue": att.dynamicData12
            }
            ],
            /*
            "SecurityAddress": [
                {
                    "Code": "",
                    "HouseName": '',
                    "HouseNumber": "",
                    "Address1": "",
                    "Address2": '',
                    "Address3": '',
                    "Address4": "",
                    "Postcode": "",
                    "CurrentValue": att.CurrentValue,
                    "SecuirtyType": att.secuirtyType,
                    "SecuirtyDetails": att.secuirtyDetails,
                    "SecuirtyCharge": null,
                    "SecuirtyChargeValue": att.SecuirtyChargeValue,
                    "SecuirtyChargeDate": null,
                    "SecuirtyEquityValue": att.SecuirtyEquityValue,
                    "SecuirtyLendingValue": att.SecuirtyLendingValue,
                    "SecuirtyPurchaseValue": 0,
                    "SecuirtyPurchaseDate": '',
                    "SecuirtyMortgageValue": 0,

                    "SecurityTitleNumber": '',
                    "SecurityCustomField": [
                        {
                            "CustomFieldNo": "1",
                            "CustomFieldValue": ""
                        },
                        {
                            "CustomFieldNo": "2",
                            "CustomFieldValue": ""
                        },
                        {
                            "CustomFieldNo": "5",
                            "CustomFieldValue": ""
                        }
                    ]
                }
            ],
            */
            "CaseNotesList": {
                "CaseNotes": {
                    "NoteType": '',
                    "ApplicantId": '',
                    "Notes": '',
                    "CreatedDate": convertDateForApi(''),
                    "CreatedBy": '',
                    "CreatedByName": '',
                    "Status": '',
                }
            }
        }
    }


    ret.CaseApp.SecurityAddress = [];
    // handle securities 

    //console.log( att['SecuirtyCode__' + i]);
    console.log('attributes available:');
    console.log(att);

    for (let i = 0; i < 20; i++) {

        if ((typeof att['SecurityType__' + i] !== 'undefined' || typeof att['SecuirtyCode__' + i] !== 'undefined') && att['SecurityType__' + i]) {


            ret.CaseApp.SecurityAddress.push(
                {
                    "_id": att['SecurityID__' + i],
                    "ForceDelete": (typeof (att['ForceDelete__' + i] != 'undefined') && att['ForceDelete__' + i] === true) ? true : false,
                    "Code": att['SecuirtyCode__' + i],
                    "HouseName": att['SecurityHouseName__' + i],
                    "HouseNumber": att['SecurityHouseNo__' + i],
                    "Address1": att['SecurityAddress1__' + i],
                    "Address2": att['SecurityAddress2__' + i],
                    "Address3": att['SecurityAddress3__' + i],
                    "Address4": att['SecurityAddress4__' + i],
                    "Postcode": att['SecurityPostcode__' + i],
                    "CurrentValue": att['CurrentValue__' + i],
                    "SecuirtyType": att['SecurityType__' + i],
                    "SecuritySubType": att['SecuritySubType__' + i],
                    "SecurityStatus": att['SecurityStatus__' + i],
                    "SecuirtyDetails": att['SecurityDetails__' + i],
                    "SecuirtyCharge": att['SecurityCharge__' + i],
                    "SecuirtyChargeValue": att['SecuirtyChargeValue__' + i],
                    "SecuirtyChargeDate": convertDateForApi(''),


                    // "SecurtyPurchaseValue": 0,
                    // "SecuirtyPurchaseDate": convertDateForApi(''),


                    "SecurityTitleNumber": att['SecurityTitle__' + i],
                    "SecurityCustomField": [
                        {
                            "CustomFieldNo": "1",
                            "CustomFieldValue": ""
                        },
                        {
                            "CustomFieldNo": "2",
                            "CustomFieldValue": ""
                        },
                        {
                            "CustomFieldNo": "5",
                            "CustomFieldValue": ""
                        }
                    ]
                }
            );
        }
    }
    return ret;
}

const getCaseAssessorPayload = (applicant_id) => {
    return {
        "UserInfo": {
            "Username": localStorage.getItem('bo_email'),
            "Password": localStorage.getItem('bo_password')
        },
        "jobCode": applicant_id
    }
}

export default {
    executeUpdateCaseReducedSec
};
