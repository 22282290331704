import alertify from 'alertifyjs';


export const config = (d) => {

    let domain = window.location.hostname;
    // let subdomain =  window.location.host.split('.')[1] ? window.location.host.split('.')[0] : false;
    
    // let base_url = 'https://brokercrm.co.uk/Webservices/BrightOfficeAPI.asmx/';
    let base_url = 'https://test.myrewardfinance.co.uk/Webservices/BrightOfficeAPI.asmx/';
    let logo = '/images/logo-large.png';
    let visible_articles = true; // globaly enabled but it can be disabled for specific users/clients
    let visible_quick_quote_btn = true;
    let assessor_questions = [44,45,46,47,48,49,50,51,52,53,54,55,56,57];
    let cleanUpNames = [''];
    let nav_links = [
        ['/form/29', 'Add Lead'],
    ]

    let forms = [
            // Cases
            // { "name": "Heads of Terms Input", "type": "link_case", "resource": "/form/8"  },
            { "name": "Heads of Terms Input", "type": "link_case", "resource": "/form/14", "b2b": true  },
            { "name": "Heads of Terms", "type": "pdf_case", "resource": "Heads of Terms", "b2b": true   },
            { "name": "Heads of Terms Input", "type": "link_case", "resource": "/form/18", "b2b": false },
            { "name": "Heads of Terms", "type": "pdf_case", "resource": "Heads of Terms Ind", "b2b": false  },
            { "name": "Proposal Form", "type": "docx_case", "resource": "/clientworddocs/rewardfinance/Proposal Form.pdf" },
            { "name": "Proposal Form Input", "type": "link_case", "resource": "/form/37", "b2b": true   },
            { "name": "Proposal Form Input", "type": "link_case", "resource": "/form/38", "b2b": false, 'group': "AAA"  },
            { "name": "Asset and Liability Statement", "type": "docx_case", "resource": "/clientworddocs/rewardfinance/Statement of Personal Assets and Liabilities.pdf"  }, 
            { "name": "Etridge Letter", "type": "docx_case", "resource": "/clientworddocs/rewardfinance/Etridge Letter to 3P Obligor.docx"  },
            { "name": "Consent Letter", "type": "docx_case", "resource": "/clientworddocs/rewardfinance/Letter of Consent (property).doc"  },
            { "name": "Risk Matrix Input", "type": "link_case", "resource": "/form/9", "b2b": true  },
            { "name": "Risk Matrix Input", "type": "link_case", "resource": "/form/20", "b2b": false  },
            { "name": "Risk Matrix", "type": "pdf_case", "resource": "Risk Matrix Portal"  },
            { "name": "Credit Paper Input", "type": "link_case", "resource": "/form/10", "b2b": true  },
            { "name": "Credit Paper Input", "type": "link_case", "resource": "/form/21", "b2b": false  },
            { "name": "Credit Paper", "type": "pdf_case", "resource": "Credit Paper", "ReturnDocType": "DOC", "b2b": false  },
            { "name": "Credit Paper", "type": "pdf_case", "resource": "Credit Paper -Business", "ReturnDocType": "DOC", "b2b": true  },
            { "name": "Drawdown Request Form", "type": "pdf_case", "resource": "Drawdown Request Form", "ReturnDocType": "DOC"  },
            { "name": "Miscellaneous Input", "type": "link_case", "resource": "/form/33", "case_status": 'prospect', "b2b": true   },
            { "name": "Miscellaneous Input", "type": "link_case", "resource": "/form/33", "case_status": 'prospect', "b2b": false  },
            { "name": "Deal Dead", "type": "link_case", "resource": "/form/15", "b2b": false  },
            { "name": "Deal Dead", "type": "link_case", "resource": "/form/23", "b2b": true  },
            { "name": "Deal Complete", "type": "link_case", "resource": "/form/13", "b2b": false    },
            { "name": "Deal Complete", "type": "link_case", "resource": "/form/22", "b2b": true  },
            // { "name": "Review Paper", "type": "pdf_case", "resource": "Review Paper", "ReturnDocType": "DOC", "case_status": 'prospect' },
            // { "name": "Drawdown Request Form", "type": "docx_case", "resource": "/clientworddocs/rewardfinance/Capital - Drawdown Request Form.doc"  },
            //Live Cases
            { "name": "Risk Matrix Input", "type": "link_livecase", "resource": "/form/9", "b2b": true  },
            { "name": "Risk Matrix Input", "type": "link_livecase", "resource": "/form/20", "b2b": false  },
            { "name": "Drawdown Request Form", "type": "pdf_livecase", "resource": "Drawdown Request Form", "ReturnDocType": "DOC"  },
            { "name": "Region Input", "type": "link_livecase", "resource": "/form/33", "b2b": true   },
            { "name": "Region Input", "type": "link_livecase", "resource": "/form/33", "b2b": false  },
            // { "name": "Review Paper", "type": "pdf_livecase", "resource": "Review Paper", "ReturnDocType": "DOC" },

            { "name": "Review Paper", "type": "pdf_case", "resource": "Review Paper", "ReturnDocType": "DOC", "case_status": 'live', "b2b": false },
            { "name": "Review Paper", "type": "pdf_case", "resource": "Review Paper - Business", "ReturnDocType": "DOC", "case_status": 'live', "b2b": true },
            // Prospects
            { "name": "Introducer Input", "type": "link_prospect", "resource": "/form/32"  },
            { "name": "Lead Input", "type": "link_prospect", "resource": "/form/36"  },
            { "name": "Convert to Prospect", "type": "link_prospect", "resource": "/form/30"  },
            { "name": "Not Proceeding", "type": "link_prospect", "resource": "/form/31"  },
    ];

 
   if(domain==='portal.myrewardfinance.co.uk') {
        base_url = 'https://myrewardfinance.co.uk/Webservices/BrightOfficeAPI.asmx/';
        logo = '/images/logo-reward-finance.svg';
        visible_articles = false;
        visible_quick_quote_btn = false;
        cleanUpNames = ['calvin.dexter', 'damien.slingsby', 'dominic.sievewright', 'ian.lewis', 'james.mortimore', 'neil.mcgivern', 'nick.battersby', 'noel.haverly', 'peter.cromarty', 'rob.mercer'];
        assessor_questions = [44,45,46,47,48,49,50,51,52,53,54,55,56,57];
        forms = [
            // Cases
            // { "name": "Heads of Terms Input", "type": "link_case", "resource": "/form/8"   },
            { "name": "Heads of Terms Input", "type": "link_case", "resource": "/form/14", "case_status": 'prospect', "b2b": true  },
            { "name": "Heads of Terms", "type": "pdf_case", "resource": "Heads of Terms", "case_status": 'prospect', "b2b": true   },
            { "name": "Heads of Terms Input", "type": "link_case", "resource": "/form/18", "case_status": 'prospect', "b2b": false },
            { "name": "Heads of Terms", "type": "pdf_case", "resource": "Heads of Terms Ind", "case_status": 'prospect', "b2b": false  },
            { "name": "Proposal Form", "type": "docx_case", "resource": "/clientworddocs/rewardfinance/Proposal Form.pdf", "case_status": 'prospect'  },
            { "name": "Proposal Form Input", "type": "link_case", "resource": "/form/37", "case_status": 'prospect', "b2b": true   },
            { "name": "Proposal Form Input", "type": "link_case", "resource": "/form/38", "case_status": 'prospect', "b2b": false  },
            { "name": "Asset and Liability Statement", "type": "docx_case", "resource": "/clientworddocs/rewardfinance/Statement of Personal Assets and Liabilities.pdf", "case_status": 'prospect'  }, 
            { "name": "Etridge Letter", "type": "docx_case", "resource": "/clientworddocs/rewardfinance/Etridge Letter to 3P Obligor.docx", "case_status": 'prospect'  },
            { "name": "Consent Letter", "type": "docx_case", "resource": "/clientworddocs/rewardfinance/Letter of Consent (property).doc", "case_status": 'prospect'  },
            { "name": "Risk Matrix Input", "type": "link_case", "resource": "/form/9", "case_status": 'prospect', "b2b": true  },
            { "name": "Risk Matrix Input", "type": "link_case", "resource": "/form/20", "case_status": 'prospect', "b2b": false  },
            { "name": "Risk Matrix", "type": "pdf_case", "resource": "Risk Matrix Portal", "case_status": 'prospect'  },
            { "name": "Credit Paper Input", "type": "link_case", "resource": "/form/10", "case_status": 'prospect', "b2b": true  },
            { "name": "Credit Paper Input", "type": "link_case", "resource": "/form/21", "case_status": 'prospect', "b2b": false  },
            { "name": "Credit Paper", "type": "pdf_case", "resource": "Credit Paper", "ReturnDocType": "DOC", "case_status": 'prospect', "b2b": false  },
            { "name": "Credit Paper", "type": "pdf_case", "resource": "Credit Paper -Business", "ReturnDocType": "DOC", "case_status": 'prospect', "b2b": true  },
            { "name": "Drawdown Request Form", "type": "pdf_case", "resource": "Drawdown Request Form", "ReturnDocType": "DOC", "case_status": 'prospect'  },
            { "name": "Deal Dead", "type": "link_case", "resource": "/form/15", "case_status": 'prospect', "b2b": false  },
            { "name": "Deal Dead", "type": "link_case", "resource": "/form/23", "case_status": 'prospect', "b2b": true  },
            { "name": "Data Review", "type": "link_case", "resource": "/form/39", "case_status": 'prospect', "b2b": false    },
            { "name": "Data Review", "type": "link_case", "resource": "/form/40", "case_status": 'prospect', "b2b": true  },
            { "name": "Deal Complete", "type": "link_case", "resource": "/form/13", "case_status": 'prospect', "b2b": false    },
            { "name": "Deal Complete", "type": "link_case", "resource": "/form/22", "case_status": 'prospect', "b2b": true  },
            // { "name": "Review Paper", "type": "pdf_case", "resource": "Review Paper", "ReturnDocType": "DOC", "case_status": 'prospect' },
            // { "name": "Miscellaneous Input", "type": "link_case", "resource": "/form/33", "case_status": 'prospect', "b2b": true   },
            // { "name": "Miscellaneous Input", "type": "link_case", "resource": "/form/33", "case_status": 'prospect', "b2b": false  },
            // { "name": "Drawdown Request Form", "type": "docx_case", "resource": "/clientworddocs/rewardfinance/Capital - Drawdown Request Form.doc"  },
            //Live Cases
            { "name": "Data Review", "type": "link_case", "resource": "/form/40", "case_status": 'live', "b2b": true  },
            { "name": "Data Review", "type": "link_case", "resource": "/form/39", "case_status": 'live', "b2b": false  },
            // { "name": "Review & Variations", "type": "link_case", "resource": "/form/42", "case_status": 'live', "b2b": true  },
            // { "name": "Review & Variations", "type": "link_case", "resource": "/form/42", "case_status": 'live', "b2b": false  },
            { "name": "Risk Matrix Input", "type": "link_case", "resource": "/form/27", "case_status": 'live', "b2b": true  },
            { "name": "Risk Matrix Input", "type": "link_case", "resource": "/form/28", "case_status": 'live', "b2b": false  },
            { "name": "Risk Matrix", "type": "pdf_case", "resource": "Risk Matrix Portal", "case_status": 'live'  },
            { "name": "Drawdown Request Form", "type": "pdf_case", "resource": "Drawdown Request Form", "ReturnDocType": "DOC", "case_status": 'live'  },
            { "name": "Review Paper", "type": "pdf_case", "resource": "Review Paper", "ReturnDocType": "DOC", "case_status": 'live', "b2b": false },
            { "name": "Review Paper", "type": "pdf_case", "resource": "Review Paper - Business", "ReturnDocType": "DOC", "case_status": 'live', "b2b": true },

            //Dead
            { "name": "Revert to Prospect", "type": "link_dead", "resource": "/form/41", "case_status": 'dead', "b2b": true  },
            { "name": "Revert to Prospect", "type": "link_dead", "resource": "/form/41", "case_status": 'dead', "b2b": false  },

            //Recovery
            { "name": "Data Review", "type": "link_recovery", "resource": "/form/40", "case_status": 'recovery', "b2b": true  },
            { "name": "Data Review", "type": "link_recovery", "resource": "/form/39", "case_status": 'recovery', "b2b": false  },
            { "name": "Risk Matrix Input", "type": "link_recovery", "resource": "/form/27", "case_status": 'recovery', "b2b": true  },
            { "name": "Risk Matrix Input", "type": "link_recovery", "resource": "/form/28", "case_status": 'recovery', "b2b": false  },
            { "name": "Risk Matrix", "type": "pdf_recovery", "resource": "Risk Matrix Portal", "case_status": 'recovery'  },
            { "name": "Drawdown Request Form", "type": "pdf_recovery", "resource": "Drawdown Request Form", "ReturnDocType": "DOC", "case_status": 'recovery'  },
            { "name": "Review Paper", "type": "pdf_recovery", "resource": "Review Paper", "ReturnDocType": "DOC", "case_status": 'recovery', "b2b": false },
            { "name": "Review Paper", "type": "pdf_recovery", "resource": "Review Paper - Business", "ReturnDocType": "DOC", "case_status": 'recovery', "b2b": true },

            //Workout
            { "name": "Data Review", "type": "link_workout", "resource": "/form/40", "case_status": 'workout', "b2b": true  },
            { "name": "Data Review", "type": "link_workout", "resource": "/form/39", "case_status": 'workout', "b2b": false  },
            { "name": "Risk Matrix Input", "type": "link_workout", "resource": "/form/27", "case_status": 'workout', "b2b": true  },
            { "name": "Risk Matrix Input", "type": "link_workout", "resource": "/form/28", "case_status": 'workout', "b2b": false  },
            { "name": "Risk Matrix", "type": "pdf_workout", "resource": "Risk Matrix Portal", "case_status": 'workout'  },
            { "name": "Drawdown Request Form", "type": "pdf_workout", "resource": "Drawdown Request Form", "ReturnDocType": "DOC", "case_status": 'workout'  },
            { "name": "Review Paper", "type": "pdf_workout", "resource": "Review Paper", "ReturnDocType": "DOC", "case_status": 'workout', "b2b": false },
            { "name": "Review Paper", "type": "pdf_workout", "resource": "Review Paper - Business", "ReturnDocType": "DOC", "case_status": 'workout', "b2b": true },

            //Repaid
            //Should Stay Blank

            // Prospects
            { "name": "Introducer Input", "type": "link_prospect", "resource": "/form/32"  },
            { "name": "Lead Input", "type": "link_prospect", "resource": "/form/36"  },
            { "name": "Convert to Prospect", "type": "link_prospect", "resource": "/form/30"  },
            { "name": "Not Proceeding", "type": "link_prospect", "resource": "/form/31"  },
        ];
    }

    if(domain==='portalalpha.myrewardfinance.co.uk') {
        base_url = 'https://myrewardfinance.co.uk/Webservices/BrightOfficeAPI.asmx/';
        logo = '/images/logo-reward-finance.svg';
        visible_articles = false;
        visible_quick_quote_btn = false;
        cleanUpNames = ['calvin.dexter', 'damien.slingsby', 'dominic.sievewright', 'ian.lewis', 'james.mortimore', 'neil.mcgivern', 'nick.battersby', 'noel.haverly', 'peter.cromarty', 'rob.mercer'];
        assessor_questions = [44,45,46,47,48,49,50,51,52,53,54,55,56,57];
        forms = [
            // Cases
            // { "name": "Heads of Terms Input", "type": "link_case", "resource": "/form/8"   },
            { "name": "Heads of Terms Input", "type": "link_case", "resource": "/form/14", "case_status": 'prospect', "b2b": true  },
            { "name": "Heads of Terms", "type": "pdf_case", "resource": "Heads of Terms", "case_status": 'prospect', "b2b": true   },
            { "name": "Heads of Terms Input", "type": "link_case", "resource": "/form/18", "case_status": 'prospect', "b2b": false },
            { "name": "Heads of Terms", "type": "pdf_case", "resource": "Heads of Terms Ind", "case_status": 'prospect', "b2b": false  },
            { "name": "Proposal Form", "type": "docx_case", "resource": "/clientworddocs/rewardfinance/Proposal Form.pdf", "case_status": 'prospect'  },
            { "name": "Proposal Form Input", "type": "link_case", "resource": "/form/37", "case_status": 'prospect', "b2b": true   },
            { "name": "Proposal Form Input", "type": "link_case", "resource": "/form/38", "case_status": 'prospect', "b2b": false  },
            { "name": "Asset and Liability Statement", "type": "docx_case", "resource": "/clientworddocs/rewardfinance/Statement of Personal Assets and Liabilities.pdf", "case_status": 'prospect'  }, 
            { "name": "Etridge Letter", "type": "docx_case", "resource": "/clientworddocs/rewardfinance/Etridge Letter to 3P Obligor.docx", "case_status": 'prospect'  },
            { "name": "Consent Letter", "type": "docx_case", "resource": "/clientworddocs/rewardfinance/Letter of Consent (property).doc", "case_status": 'prospect'  },
            { "name": "Risk Matrix Input", "type": "link_case", "resource": "/form/9", "case_status": 'prospect', "b2b": true  },
            { "name": "Risk Matrix Input", "type": "link_case", "resource": "/form/20", "case_status": 'prospect', "b2b": false  },
            { "name": "Risk Matrix", "type": "pdf_case", "resource": "Risk Matrix Portal", "case_status": 'prospect'  },
            { "name": "Credit Paper Input", "type": "link_case", "resource": "/form/10", "case_status": 'prospect', "b2b": true  },
            { "name": "Credit Paper Input", "type": "link_case", "resource": "/form/21", "case_status": 'prospect', "b2b": false  },
            { "name": "Credit Paper", "type": "pdf_case", "resource": "Credit Paper", "ReturnDocType": "DOC", "case_status": 'prospect', "b2b": false  },
            { "name": "Credit Paper", "type": "pdf_case", "resource": "Credit Paper -Business", "ReturnDocType": "DOC", "case_status": 'prospect', "b2b": true  },
            { "name": "Drawdown Request Form", "type": "pdf_case", "resource": "Drawdown Request Form", "ReturnDocType": "DOC", "case_status": 'prospect'  },
            { "name": "Deal Dead", "type": "link_case", "resource": "/form/15", "case_status": 'prospect', "b2b": false  },
            { "name": "Deal Dead", "type": "link_case", "resource": "/form/23", "case_status": 'prospect', "b2b": true  },
            { "name": "Data Review", "type": "link_case", "resource": "/form/39", "case_status": 'prospect', "b2b": false    },
            { "name": "Data Review", "type": "link_case", "resource": "/form/40", "case_status": 'prospect', "b2b": true  },
            { "name": "Deal Complete", "type": "link_case", "resource": "/form/13", "case_status": 'prospect', "b2b": false    },
            { "name": "Deal Complete", "type": "link_case", "resource": "/form/22", "case_status": 'prospect', "b2b": true  },
            // { "name": "Review Paper", "type": "pdf_case", "resource": "Review Paper", "ReturnDocType": "DOC", "case_status": 'prospect' },
            // { "name": "Miscellaneous Input", "type": "link_case", "resource": "/form/33", "case_status": 'prospect', "b2b": true   },
            // { "name": "Miscellaneous Input", "type": "link_case", "resource": "/form/33", "case_status": 'prospect', "b2b": false  },
            // { "name": "Drawdown Request Form", "type": "docx_case", "resource": "/clientworddocs/rewardfinance/Capital - Drawdown Request Form.doc"  },
            //Live Cases
            { "name": "Data Review", "type": "link_case", "resource": "/form/40", "case_status": 'live', "b2b": true  },
            { "name": "Data Review", "type": "link_case", "resource": "/form/39", "case_status": 'live', "b2b": false  },
            // { "name": "Review & Variations", "type": "link_case", "resource": "/form/42", "case_status": 'live', "b2b": true  },
            // { "name": "Review & Variations", "type": "link_case", "resource": "/form/42", "case_status": 'live', "b2b": false  },
            { "name": "Risk Matrix Input", "type": "link_case", "resource": "/form/27", "case_status": 'live', "b2b": true  },
            { "name": "Risk Matrix Input", "type": "link_case", "resource": "/form/28", "case_status": 'live', "b2b": false  },
            { "name": "Risk Matrix", "type": "pdf_case", "resource": "Risk Matrix Portal", "case_status": 'live'  },
            { "name": "Drawdown Request Form", "type": "pdf_case", "resource": "Drawdown Request Form", "ReturnDocType": "DOC", "case_status": 'live'  },
            { "name": "Review Paper", "type": "pdf_case", "resource": "Review Paper", "ReturnDocType": "DOC", "case_status": 'live', "b2b": false },
            { "name": "Review Paper", "type": "pdf_case", "resource": "Review Paper - Business", "ReturnDocType": "DOC", "case_status": 'live', "b2b": true },

            //Dead
            { "name": "Revert to Prospect", "type": "link_dead", "resource": "/form/41", "case_status": 'dead', "b2b": true  },
            { "name": "Revert to Prospect", "type": "link_dead", "resource": "/form/41", "case_status": 'dead', "b2b": false  },

            //Recovery
            { "name": "Data Review", "type": "link_recovery", "resource": "/form/40", "case_status": 'recovery', "b2b": true  },
            { "name": "Data Review", "type": "link_recovery", "resource": "/form/39", "case_status": 'recovery', "b2b": false  },
            { "name": "Risk Matrix Input", "type": "link_recovery", "resource": "/form/27", "case_status": 'recovery', "b2b": true  },
            { "name": "Risk Matrix Input", "type": "link_recovery", "resource": "/form/28", "case_status": 'recovery', "b2b": false  },
            { "name": "Risk Matrix", "type": "pdf_recovery", "resource": "Risk Matrix Portal", "case_status": 'recovery'  },
            { "name": "Drawdown Request Form", "type": "pdf_recovery", "resource": "Drawdown Request Form", "ReturnDocType": "DOC", "case_status": 'recovery'  },
            { "name": "Review Paper", "type": "pdf_recovery", "resource": "Review Paper", "ReturnDocType": "DOC", "case_status": 'recovery', "b2b": false },
            { "name": "Review Paper", "type": "pdf_recovery", "resource": "Review Paper - Business", "ReturnDocType": "DOC", "case_status": 'recovery', "b2b": true },

            //Workout
            { "name": "Data Review", "type": "link_workout", "resource": "/form/40", "case_status": 'workout', "b2b": true  },
            { "name": "Data Review", "type": "link_workout", "resource": "/form/39", "case_status": 'workout', "b2b": false  },
            { "name": "Risk Matrix Input", "type": "link_workout", "resource": "/form/27", "case_status": 'workout', "b2b": true  },
            { "name": "Risk Matrix Input", "type": "link_workout", "resource": "/form/28", "case_status": 'workout', "b2b": false  },
            { "name": "Risk Matrix", "type": "pdf_workout", "resource": "Risk Matrix Portal", "case_status": 'workout'  },
            { "name": "Drawdown Request Form", "type": "pdf_workout", "resource": "Drawdown Request Form", "ReturnDocType": "DOC", "case_status": 'workout'  },
            { "name": "Review Paper", "type": "pdf_workout", "resource": "Review Paper", "ReturnDocType": "DOC", "case_status": 'workout', "b2b": false },
            { "name": "Review Paper", "type": "pdf_workout", "resource": "Review Paper - Business", "ReturnDocType": "DOC", "case_status": 'workout', "b2b": true },

            //Repaid
            //Should Stay Blank

            // Prospects
            { "name": "Introducer Input", "type": "link_prospect", "resource": "/form/32"  },
            { "name": "Lead Input", "type": "link_prospect", "resource": "/form/36"  },
            { "name": "Convert to Prospect", "type": "link_prospect", "resource": "/form/30"  },
            { "name": "Not Proceeding", "type": "link_prospect", "resource": "/form/31"  },
        ];
    }


    if(domain==='portalbeta.myrewardfinance.co.uk') {
        base_url = 'https://test.myrewardfinance.co.uk/Webservices/BrightOfficeAPI.asmx/';
        logo = '/images/logo-reward-finance.svg';
        visible_articles = false;
        visible_quick_quote_btn = false;
        cleanUpNames = ['calvin.dexter', 'damien.slingsby', 'dominic.sievewright', 'ian.lewis', 'james.mortimore', 'neil.mcgivern', 'nick.battersby', 'noel.haverly', 'peter.cromarty', 'rob.mercer'];
        assessor_questions = [44,45,46,47,48,49,50,51,52,53,54,55,56,57];
        forms = [
            // Cases
            // { "name": "Heads of Terms Input", "type": "link_case", "resource": "/form/8"   },
            { "name": "Heads of Terms Input", "type": "link_case", "resource": "/form/14", "case_status": 'prospect', "b2b": true  },
            { "name": "Heads of Terms", "type": "pdf_case", "resource": "Heads of Terms", "case_status": 'prospect', "b2b": true   },
            { "name": "Heads of Terms Input", "type": "link_case", "resource": "/form/18", "case_status": 'prospect', "b2b": false },
            { "name": "Heads of Terms", "type": "pdf_case", "resource": "Heads of Terms Ind", "case_status": 'prospect', "b2b": false  },
            { "name": "Proposal Form", "type": "docx_case", "resource": "/clientworddocs/rewardfinance/Proposal Form.pdf", "case_status": 'prospect'  },
            { "name": "Proposal Form Input", "type": "link_case", "resource": "/form/37", "case_status": 'prospect', "b2b": true   },
            { "name": "Proposal Form Input", "type": "link_case", "resource": "/form/38", "case_status": 'prospect', "b2b": false  },
            { "name": "Asset and Liability Statement", "type": "docx_case", "resource": "/clientworddocs/rewardfinance/Statement of Personal Assets and Liabilities.pdf", "case_status": 'prospect'  }, 
            { "name": "Etridge Letter", "type": "docx_case", "resource": "/clientworddocs/rewardfinance/Etridge Letter to 3P Obligor.docx", "case_status": 'prospect'  },
            { "name": "Consent Letter", "type": "docx_case", "resource": "/clientworddocs/rewardfinance/Letter of Consent (property).doc", "case_status": 'prospect'  },
            { "name": "Risk Matrix Input", "type": "link_case", "resource": "/form/9", "case_status": 'prospect', "b2b": true  },
            { "name": "Risk Matrix Input", "type": "link_case", "resource": "/form/20", "case_status": 'prospect', "b2b": false  },
            { "name": "Risk Matrix", "type": "pdf_case", "resource": "Risk Matrix Portal", "case_status": 'prospect'  },
            { "name": "Credit Paper Input", "type": "link_case", "resource": "/form/10", "case_status": 'prospect', "b2b": true  },
            { "name": "Credit Paper Input", "type": "link_case", "resource": "/form/21", "case_status": 'prospect', "b2b": false  },
            { "name": "Credit Paper", "type": "pdf_case", "resource": "Credit Paper", "ReturnDocType": "DOC", "case_status": 'prospect', "b2b": false  },
            { "name": "Credit Paper", "type": "pdf_case", "resource": "Credit Paper -Business", "ReturnDocType": "DOC", "case_status": 'prospect', "b2b": true  },
            { "name": "Drawdown Request Form", "type": "pdf_case", "resource": "Drawdown Request Form", "ReturnDocType": "DOC", "case_status": 'prospect'  },
            { "name": "Deal Dead", "type": "link_case", "resource": "/form/15", "case_status": 'prospect', "b2b": false  },
            { "name": "Deal Dead", "type": "link_case", "resource": "/form/23", "case_status": 'prospect', "b2b": true  },
            { "name": "Data Review", "type": "link_case", "resource": "/form/39", "case_status": 'prospect', "b2b": false    },
            { "name": "Data Review", "type": "link_case", "resource": "/form/40", "case_status": 'prospect', "b2b": true  },
            { "name": "Deal Complete", "type": "link_case", "resource": "/form/13", "case_status": 'prospect', "b2b": false    },
            { "name": "Deal Complete", "type": "link_case", "resource": "/form/22", "case_status": 'prospect', "b2b": true  },
            // { "name": "Review Paper", "type": "pdf_case", "resource": "Review Paper", "ReturnDocType": "DOC", "case_status": 'prospect' },
            // { "name": "Miscellaneous Input", "type": "link_case", "resource": "/form/33", "case_status": 'prospect', "b2b": true   },
            // { "name": "Miscellaneous Input", "type": "link_case", "resource": "/form/33", "case_status": 'prospect', "b2b": false  },
            // { "name": "Drawdown Request Form", "type": "docx_case", "resource": "/clientworddocs/rewardfinance/Capital - Drawdown Request Form.doc"  },
            //Live Cases
            { "name": "Data Review", "type": "link_case", "resource": "/form/40", "case_status": 'live', "b2b": true  },
            { "name": "Data Review", "type": "link_case", "resource": "/form/39", "case_status": 'live', "b2b": false  },
            { "name": "Risk Matrix Input", "type": "link_case", "resource": "/form/27", "case_status": 'live', "b2b": true  },
            { "name": "Risk Matrix Input", "type": "link_case", "resource": "/form/28", "case_status": 'live', "b2b": false  },
            { "name": "Risk Matrix", "type": "pdf_case", "resource": "Risk Matrix Portal", "case_status": 'live'  },
            { "name": "Drawdown Request Form", "type": "pdf_case", "resource": "Drawdown Request Form", "ReturnDocType": "DOC", "case_status": 'live'  },
            // { "name": "Review Paper", "type": "pdf_case", "resource": "Review Paper", "ReturnDocType": "DOC", "case_status": 'live', "b2b": false },
            // { "name": "Review Paper", "type": "pdf_case", "resource": "Review Paper - Business", "ReturnDocType": "DOC", "case_status": 'live', "b2b": true },
            //Review Variation Section in Live cases
            { "name": "Variation Input", "type": "link_case", "resource": "/form/42", "case_status": 'live', "b2b": true, 'group': "Variation" },
            { "name": "Variation Input", "type": "link_case", "resource": "/form/42", "case_status": 'live', "b2b": false, 'group': "Variation" },
            { "name": "Risk Matrix Input", "type": "link_case", "resource": "/form/44", "case_status": 'live', "b2b": true, 'group': "Variation"  },
            { "name": "Risk Matrix Input", "type": "link_case", "resource": "/form/45", "case_status": 'live', "b2b": false, 'group': "Variation"  },
            { "name": "Risk Matrix", "type": "pdf_case", "resource": "Risk Matrix Review", "case_status": 'live', "b2b": false, 'group': "Variation" },
            { "name": "Risk Matrix", "type": "pdf_case", "resource": "Risk Matrix Review", "case_status": 'live', "b2b": true, 'group': "Variation" },
            { "name": "Variation Paper", "type": "pdf_case", "resource": "Review Paper - Business New", "ReturnDocType": "DOC", "case_status": 'live', "b2b": false, 'group': "Variation"  },
            { "name": "Variation Paper", "type": "pdf_case", "resource": "Review Paper - Business New", "ReturnDocType": "DOC", "case_status": 'live', "b2b": true, 'group': "Variation"  },
            { "name": "Variation Submission", "type": "link_case", "resource": "/form/43", "case_status": 'live', "b2b": true, 'group': "Variation"  },
            { "name": "Variation Submission", "type": "link_case", "resource": "/form/43", "case_status": 'live', "b2b": false, 'group': "Variation"  },
            //New Self area
            { "name": "Self Certification Input", "type": "link_case", "resource": "/form/48", "case_status": 'live', "b2b": true, 'group': "Self Certification"  },
            { "name": "Self Certification Input", "type": "link_case", "resource": "/form/48", "case_status": 'live', "b2b": false, 'group': "Self Certification"   },
            { "name": "Risk Matrix Input", "type": "link_case", "resource": "/form/44", "case_status": 'live', "b2b": true, 'group': "Self Certification"   },
            { "name": "Risk Matrix Input", "type": "link_case", "resource": "/form/45", "case_status": 'live', "b2b": false, 'group': "Self Certification"   },
            { "name": "Risk Matrix", "type": "pdf_case", "resource": "Risk Matrix Review", "case_status": 'live', "b2b": false, 'group': "Self Certification"   },
            { "name": "Risk Matrix", "type": "pdf_case", "resource": "Risk Matrix Review", "case_status": 'live', "b2b": true, 'group': "Self Certification"   },
            { "name": "Self Certification Paper", "type": "pdf_case", "resource": "Self Certification Review", "ReturnDocType": "DOC", "case_status": 'live', "b2b": false, 'group': "Self Certification" },
            { "name": "Self Certification Paper", "type": "pdf_case", "resource": "Self Certification Review B2B", "ReturnDocType": "DOC", "case_status": 'live', "b2b": true, 'group': "Self Certification" },
            { "name": "Self Certification Submission", "type": "link_case", "resource": "/form/49", "case_status": 'live', "b2b": true, 'group': "Self Certification"   },
            { "name": "Self Certification Submission", "type": "link_case", "resource": "/form/49", "case_status": 'live', "b2b": false, 'group': "Self Certification"   },
            // { "name": "Risk Matrix Variation Sub", "type": "link_case", "resource": "/form/47", "case_status": 'live', "b2b": true  },
            // { "name": "Risk Matrix Variation Sub", "type": "link_case", "resource": "/form/46", "case_status": 'live', "b2b": false  },


            //Dead
            { "name": "Revert to Prospect", "type": "link_dead", "resource": "/form/41", "case_status": 'dead', "b2b": true  },
            { "name": "Revert to Prospect", "type": "link_dead", "resource": "/form/41", "case_status": 'dead', "b2b": false  },

            //Recovery
            { "name": "Data Review", "type": "link_recovery", "resource": "/form/40", "case_status": 'recovery', "b2b": true  },
            { "name": "Data Review", "type": "link_recovery", "resource": "/form/39", "case_status": 'recovery', "b2b": false  },
            { "name": "Risk Matrix Input", "type": "link_recovery", "resource": "/form/27", "case_status": 'recovery', "b2b": true  },
            { "name": "Risk Matrix Input", "type": "link_recovery", "resource": "/form/28", "case_status": 'recovery', "b2b": false  },
            { "name": "Risk Matrix", "type": "pdf_recovery", "resource": "Risk Matrix Portal", "case_status": 'recovery'  },
            { "name": "Drawdown Request Form", "type": "pdf_recovery", "resource": "Drawdown Request Form", "ReturnDocType": "DOC", "case_status": 'recovery'  },
            { "name": "Review Paper", "type": "pdf_recovery", "resource": "Review Paper", "ReturnDocType": "DOC", "case_status": 'recovery', "b2b": false },
            { "name": "Review Paper", "type": "pdf_recovery", "resource": "Review Paper - Business", "ReturnDocType": "DOC", "case_status": 'recovery', "b2b": true },

            //Workout
            { "name": "Data Review", "type": "link_workout", "resource": "/form/40", "case_status": 'workout', "b2b": true  },
            { "name": "Data Review", "type": "link_workout", "resource": "/form/39", "case_status": 'workout', "b2b": false  },
            { "name": "Risk Matrix Input", "type": "link_workout", "resource": "/form/27", "case_status": 'workout', "b2b": true  },
            { "name": "Risk Matrix Input", "type": "link_workout", "resource": "/form/28", "case_status": 'workout', "b2b": false  },
            { "name": "Risk Matrix", "type": "pdf_workout", "resource": "Risk Matrix Portal", "case_status": 'workout'  },
            { "name": "Drawdown Request Form", "type": "pdf_workout", "resource": "Drawdown Request Form", "ReturnDocType": "DOC", "case_status": 'workout'  },
            { "name": "Review Paper", "type": "pdf_workout", "resource": "Review Paper", "ReturnDocType": "DOC", "case_status": 'workout', "b2b": false },
            { "name": "Review Paper", "type": "pdf_workout", "resource": "Review Paper - Business", "ReturnDocType": "DOC", "case_status": 'workout', "b2b": true },

            //Repaid
            //Should Stay Blank

            // Prospects
            { "name": "Introducer Input", "type": "link_prospect", "resource": "/form/32"  },
            { "name": "Lead Input", "type": "link_prospect", "resource": "/form/36"  },
            { "name": "Convert to Prospect", "type": "link_prospect", "resource": "/form/30"  },
            { "name": "Not Proceeding", "type": "link_prospect", "resource": "/form/31"  },
        ];
    }

    if(domain==='share.myrewardfinance.co.uk') {
        base_url = 'https://test.myrewardfinance.co.uk/Webservices/BrightOfficeAPI.asmx/';
        logo = '/images/logo-reward-finance.svg';
        visible_articles = false;
        visible_quick_quote_btn = false;
        cleanUpNames = ['calvin.dexter', 'damien.slingsby', 'dominic.sievewright', 'ian.lewis', 'james.mortimore', 'neil.mcgivern', 'nick.battersby', 'noel.haverly', 'peter.cromarty', 'rob.mercer'];
        assessor_questions = [44,45,46,47,48,49,50,51,52,53,54,55,56,57];
        forms = [
            // Cases
            // { "name": "Heads of Terms Input", "type": "link_case", "resource": "/form/8"   },
            { "name": "Heads of Terms Input", "type": "link_case", "resource": "/form/14", "case_status": 'prospect', "b2b": true  },
            { "name": "Heads of Terms", "type": "pdf_case", "resource": "Heads of Terms", "case_status": 'prospect', "b2b": true   },
            { "name": "Heads of Terms Input", "type": "link_case", "resource": "/form/18", "case_status": 'prospect', "b2b": false },
            { "name": "Heads of Terms", "type": "pdf_case", "resource": "Heads of Terms Ind", "case_status": 'prospect', "b2b": false  },
            { "name": "Proposal Form", "type": "docx_case", "resource": "/clientworddocs/rewardfinance/Proposal Form.pdf", "case_status": 'prospect'  },
            { "name": "Proposal Form Input", "type": "link_case", "resource": "/form/37", "case_status": 'prospect', "b2b": true   },
            { "name": "Proposal Form Input", "type": "link_case", "resource": "/form/38", "case_status": 'prospect', "b2b": false  },
            { "name": "Asset and Liability Statement", "type": "docx_case", "resource": "/clientworddocs/rewardfinance/Statement of Personal Assets and Liabilities.pdf", "case_status": 'prospect'  }, 
            { "name": "Etridge Letter", "type": "docx_case", "resource": "/clientworddocs/rewardfinance/Etridge Letter to 3P Obligor.docx", "case_status": 'prospect'  },
            { "name": "Consent Letter", "type": "docx_case", "resource": "/clientworddocs/rewardfinance/Letter of Consent (property).doc", "case_status": 'prospect'  },
            { "name": "Risk Matrix Input", "type": "link_case", "resource": "/form/9", "case_status": 'prospect', "b2b": true  },
            { "name": "Risk Matrix Input", "type": "link_case", "resource": "/form/20", "case_status": 'prospect', "b2b": false  },
            { "name": "Risk Matrix", "type": "pdf_case", "resource": "Risk Matrix Portal", "case_status": 'prospect'  },
            { "name": "Credit Paper Input", "type": "link_case", "resource": "/form/10", "case_status": 'prospect', "b2b": true  },
            { "name": "Credit Paper Input", "type": "link_case", "resource": "/form/21", "case_status": 'prospect', "b2b": false  },
            { "name": "Credit Paper", "type": "pdf_case", "resource": "Credit Paper", "ReturnDocType": "DOC", "case_status": 'prospect', "b2b": false  },
            { "name": "Credit Paper", "type": "pdf_case", "resource": "Credit Paper -Business", "ReturnDocType": "DOC", "case_status": 'prospect', "b2b": true  },
            { "name": "Drawdown Request Form", "type": "pdf_case", "resource": "Drawdown Request Form", "ReturnDocType": "DOC", "case_status": 'prospect'  },
            { "name": "Deal Dead", "type": "link_case", "resource": "/form/15", "case_status": 'prospect', "b2b": false  },
            { "name": "Deal Dead", "type": "link_case", "resource": "/form/23", "case_status": 'prospect', "b2b": true  },
            { "name": "Data Review", "type": "link_case", "resource": "/form/39", "case_status": 'prospect', "b2b": false    },
            { "name": "Data Review", "type": "link_case", "resource": "/form/40", "case_status": 'prospect', "b2b": true  },
            { "name": "Deal Complete", "type": "link_case", "resource": "/form/13", "case_status": 'prospect', "b2b": false    },
            { "name": "Deal Complete", "type": "link_case", "resource": "/form/22", "case_status": 'prospect', "b2b": true  },
            // { "name": "Review Paper", "type": "pdf_case", "resource": "Review Paper", "ReturnDocType": "DOC", "case_status": 'prospect' },
            // { "name": "Miscellaneous Input", "type": "link_case", "resource": "/form/33", "case_status": 'prospect', "b2b": true   },
            // { "name": "Miscellaneous Input", "type": "link_case", "resource": "/form/33", "case_status": 'prospect', "b2b": false  },
            // { "name": "Drawdown Request Form", "type": "docx_case", "resource": "/clientworddocs/rewardfinance/Capital - Drawdown Request Form.doc"  },
            //Live Cases
            { "name": "Data Review", "type": "link_case", "resource": "/form/40", "case_status": 'live', "b2b": true  },
            { "name": "Data Review", "type": "link_case", "resource": "/form/39", "case_status": 'live', "b2b": false  },
            // { "name": "Review & Variations", "type": "link_case", "resource": "/form/42", "case_status": 'live', "b2b": true  },
            // { "name": "Review & Variations", "type": "link_case", "resource": "/form/42", "case_status": 'live', "b2b": false  },
            { "name": "Risk Matrix Input", "type": "link_case", "resource": "/form/27", "case_status": 'live', "b2b": true  },
            { "name": "Risk Matrix Input", "type": "link_case", "resource": "/form/28", "case_status": 'live', "b2b": false  },
            { "name": "Risk Matrix", "type": "pdf_case", "resource": "Risk Matrix Portal", "case_status": 'live'  },
            { "name": "Drawdown Request Form", "type": "pdf_case", "resource": "Drawdown Request Form", "ReturnDocType": "DOC", "case_status": 'live'  },
            { "name": "Review Paper", "type": "pdf_case", "resource": "Review Paper", "ReturnDocType": "DOC", "case_status": 'live', "b2b": false },
            { "name": "Review Paper", "type": "pdf_case", "resource": "Review Paper - Business", "ReturnDocType": "DOC", "case_status": 'live', "b2b": true },

            //Dead
            { "name": "Revert to Prospect", "type": "link_dead", "resource": "/form/41", "case_status": 'dead', "b2b": true  },
            { "name": "Revert to Prospect", "type": "link_dead", "resource": "/form/41", "case_status": 'dead', "b2b": false  },

            //Recovery
            { "name": "Data Review", "type": "link_recovery", "resource": "/form/40", "case_status": 'recovery', "b2b": true  },
            { "name": "Data Review", "type": "link_recovery", "resource": "/form/39", "case_status": 'recovery', "b2b": false  },
            { "name": "Risk Matrix Input", "type": "link_recovery", "resource": "/form/27", "case_status": 'recovery', "b2b": true  },
            { "name": "Risk Matrix Input", "type": "link_recovery", "resource": "/form/28", "case_status": 'recovery', "b2b": false  },
            { "name": "Risk Matrix", "type": "pdf_recovery", "resource": "Risk Matrix Portal", "case_status": 'recovery'  },
            { "name": "Drawdown Request Form", "type": "pdf_recovery", "resource": "Drawdown Request Form", "ReturnDocType": "DOC", "case_status": 'recovery'  },
            { "name": "Review Paper", "type": "pdf_recovery", "resource": "Review Paper", "ReturnDocType": "DOC", "case_status": 'recovery', "b2b": false },
            { "name": "Review Paper", "type": "pdf_recovery", "resource": "Review Paper - Business", "ReturnDocType": "DOC", "case_status": 'recovery', "b2b": true },

            //Workout
            { "name": "Data Review", "type": "link_workout", "resource": "/form/40", "case_status": 'workout', "b2b": true  },
            { "name": "Data Review", "type": "link_workout", "resource": "/form/39", "case_status": 'workout', "b2b": false  },
            { "name": "Risk Matrix Input", "type": "link_workout", "resource": "/form/27", "case_status": 'workout', "b2b": true  },
            { "name": "Risk Matrix Input", "type": "link_workout", "resource": "/form/28", "case_status": 'workout', "b2b": false  },
            { "name": "Risk Matrix", "type": "pdf_workout", "resource": "Risk Matrix Portal", "case_status": 'workout'  },
            { "name": "Drawdown Request Form", "type": "pdf_workout", "resource": "Drawdown Request Form", "ReturnDocType": "DOC", "case_status": 'workout'  },
            { "name": "Review Paper", "type": "pdf_workout", "resource": "Review Paper", "ReturnDocType": "DOC", "case_status": 'workout', "b2b": false },
            { "name": "Review Paper", "type": "pdf_workout", "resource": "Review Paper - Business", "ReturnDocType": "DOC", "case_status": 'workout', "b2b": true },

            //Repaid
            //Should Stay Blank

            // Prospects
            { "name": "Introducer Input", "type": "link_prospect", "resource": "/form/32"  },
            { "name": "Lead Input", "type": "link_prospect", "resource": "/form/36"  },
            { "name": "Convert to Prospect", "type": "link_prospect", "resource": "/form/30"  },
            { "name": "Not Proceeding", "type": "link_prospect", "resource": "/form/31"  },
        ];
    }

    if(domain==='portalgamma.myrewardfinance.co.uk') {
        base_url = 'https://test.myrewardfinance.co.uk/Webservices/BrightOfficeAPI.asmx/';
        logo = '/images/logo-reward-finance.svg';
        visible_articles = false;
        visible_quick_quote_btn = false;
        cleanUpNames = ['calvin.dexter', 'damien.slingsby', 'dominic.sievewright', 'ian.lewis', 'james.mortimore', 'neil.mcgivern', 'nick.battersby', 'noel.haverly', 'peter.cromarty', 'rob.mercer'];
        assessor_questions = [44,45,46,47,48,49,50,51,52,53,54,55,56,57];
        forms = [
            // Cases
            // { "name": "Heads of Terms Input", "type": "link_case", "resource": "/form/8"   },
            { "name": "Heads of Terms Input", "type": "link_case", "resource": "/form/14", "case_status": 'prospect', "b2b": true  },
            { "name": "Heads of Terms", "type": "pdf_case", "resource": "Heads of Terms", "case_status": 'prospect', "b2b": true   },
            { "name": "Heads of Terms Input", "type": "link_case", "resource": "/form/18", "case_status": 'prospect', "b2b": false },
            { "name": "Heads of Terms", "type": "pdf_case", "resource": "Heads of Terms Ind", "case_status": 'prospect', "b2b": false  },
            { "name": "Proposal Form", "type": "docx_case", "resource": "/clientworddocs/rewardfinance/Proposal Form.pdf", "case_status": 'prospect'  },
            { "name": "Proposal Form Input", "type": "link_case", "resource": "/form/37", "case_status": 'prospect', "b2b": true   },
            { "name": "Proposal Form Input", "type": "link_case", "resource": "/form/38", "case_status": 'prospect', "b2b": false  },
            { "name": "Asset and Liability Statement", "type": "docx_case", "resource": "/clientworddocs/rewardfinance/Statement of Personal Assets and Liabilities.pdf", "case_status": 'prospect'  }, 
            { "name": "Etridge Letter", "type": "docx_case", "resource": "/clientworddocs/rewardfinance/Etridge Letter to 3P Obligor.docx", "case_status": 'prospect'  },
            { "name": "Consent Letter", "type": "docx_case", "resource": "/clientworddocs/rewardfinance/Letter of Consent (property).doc", "case_status": 'prospect'  },
            { "name": "Risk Matrix Input", "type": "link_case", "resource": "/form/9", "case_status": 'prospect', "b2b": true  },
            { "name": "Risk Matrix Input", "type": "link_case", "resource": "/form/20", "case_status": 'prospect', "b2b": false  },
            { "name": "Risk Matrix", "type": "pdf_case", "resource": "Risk Matrix Portal", "case_status": 'prospect'  },
            { "name": "Credit Paper Input", "type": "link_case", "resource": "/form/10", "case_status": 'prospect', "b2b": true  },
            { "name": "Credit Paper Input", "type": "link_case", "resource": "/form/21", "case_status": 'prospect', "b2b": false  },
            { "name": "Credit Paper", "type": "pdf_case", "resource": "Credit Paper", "ReturnDocType": "DOC", "case_status": 'prospect', "b2b": false  },
            { "name": "Credit Paper", "type": "pdf_case", "resource": "Credit Paper -Business", "ReturnDocType": "DOC", "case_status": 'prospect', "b2b": true  },
            { "name": "Drawdown Request Form", "type": "pdf_case", "resource": "Drawdown Request Form", "ReturnDocType": "DOC", "case_status": 'prospect'  },
            { "name": "Deal Dead", "type": "link_case", "resource": "/form/15", "case_status": 'prospect', "b2b": false  },
            { "name": "Deal Dead", "type": "link_case", "resource": "/form/23", "case_status": 'prospect', "b2b": true  },
            { "name": "Data Review", "type": "link_case", "resource": "/form/39", "case_status": 'prospect', "b2b": false    },
            { "name": "Data Review", "type": "link_case", "resource": "/form/40", "case_status": 'prospect', "b2b": true  },
            { "name": "Deal Complete", "type": "link_case", "resource": "/form/13", "case_status": 'prospect', "b2b": false    },
            { "name": "Deal Complete", "type": "link_case", "resource": "/form/22", "case_status": 'prospect', "b2b": true  },
            // { "name": "Review Paper", "type": "pdf_case", "resource": "Review Paper", "ReturnDocType": "DOC", "case_status": 'prospect' },
            // { "name": "Miscellaneous Input", "type": "link_case", "resource": "/form/33", "case_status": 'prospect', "b2b": true   },
            // { "name": "Miscellaneous Input", "type": "link_case", "resource": "/form/33", "case_status": 'prospect', "b2b": false  },
            // { "name": "Drawdown Request Form", "type": "docx_case", "resource": "/clientworddocs/rewardfinance/Capital - Drawdown Request Form.doc"  },
            //Live Cases
            { "name": "Data Review", "type": "link_case", "resource": "/form/40", "case_status": 'live', "b2b": true  },
            { "name": "Data Review", "type": "link_case", "resource": "/form/39", "case_status": 'live', "b2b": false  },
            { "name": "Risk Matrix Input", "type": "link_case", "resource": "/form/27", "case_status": 'live', "b2b": true  },
            { "name": "Risk Matrix Input", "type": "link_case", "resource": "/form/28", "case_status": 'live', "b2b": false  },
            { "name": "Risk Matrix", "type": "pdf_case", "resource": "Risk Matrix Portal", "case_status": 'live'  },
            { "name": "Drawdown Request Form", "type": "pdf_case", "resource": "Drawdown Request Form", "ReturnDocType": "DOC", "case_status": 'live'  },
            // { "name": "Review Paper", "type": "pdf_case", "resource": "Review Paper", "ReturnDocType": "DOC", "case_status": 'live', "b2b": false },
            // { "name": "Review Paper", "type": "pdf_case", "resource": "Review Paper - Business", "ReturnDocType": "DOC", "case_status": 'live', "b2b": true },
            //Review Variation Section in Live cases
            { "name": "Variation Input", "type": "link_case", "resource": "/form/42", "case_status": 'live', "b2b": true, 'group': "Variation" },
            { "name": "Variation Input", "type": "link_case", "resource": "/form/42", "case_status": 'live', "b2b": false, 'group': "Variation" },
            { "name": "Risk Matrix Input", "type": "link_case", "resource": "/form/44", "case_status": 'live', "b2b": true, 'group': "Variation"  },
            { "name": "Risk Matrix Input", "type": "link_case", "resource": "/form/45", "case_status": 'live', "b2b": false, 'group': "Variation"  },
            { "name": "Risk Matrix", "type": "pdf_case", "resource": "Risk Matrix Review", "case_status": 'live', "b2b": false, 'group': "Variation" },
            { "name": "Risk Matrix", "type": "pdf_case", "resource": "Risk Matrix Review", "case_status": 'live', "b2b": true, 'group': "Variation" },
            { "name": "Variation Paper", "type": "pdf_case", "resource": "Review Paper - Business New", "ReturnDocType": "DOC", "case_status": 'live', "b2b": false, 'group': "Variation"  },
            { "name": "Variation Paper", "type": "pdf_case", "resource": "Review Paper - Business New", "ReturnDocType": "DOC", "case_status": 'live', "b2b": true, 'group': "Variation"  },
            { "name": "Variation Submission", "type": "link_case", "resource": "/form/43", "case_status": 'live', "b2b": true, 'group': "Variation"  },
            { "name": "Variation Submission", "type": "link_case", "resource": "/form/43", "case_status": 'live', "b2b": false, 'group': "Variation"  },
            //New Self area
            { "name": "Self Certification Input", "type": "link_case", "resource": "/form/48", "case_status": 'live', "b2b": true, 'group': "Self Certification"  },
            { "name": "Self Certification Input", "type": "link_case", "resource": "/form/48", "case_status": 'live', "b2b": false, 'group': "Self Certification"   },
            { "name": "Risk Matrix Input", "type": "link_case", "resource": "/form/44", "case_status": 'live', "b2b": true, 'group': "Self Certification"   },
            { "name": "Risk Matrix Input", "type": "link_case", "resource": "/form/45", "case_status": 'live', "b2b": false, 'group': "Self Certification"   },
            { "name": "Risk Matrix", "type": "pdf_case", "resource": "Risk Matrix Review", "case_status": 'live', "b2b": false, 'group': "Self Certification"   },
            { "name": "Risk Matrix", "type": "pdf_case", "resource": "Risk Matrix Review", "case_status": 'live', "b2b": true, 'group': "Self Certification"   },
            { "name": "Self Certification Paper", "type": "pdf_case", "resource": "Self Certification Review", "ReturnDocType": "DOC", "case_status": 'live', "b2b": false, 'group': "Self Certification" },
            { "name": "Self Certification Paper", "type": "pdf_case", "resource": "Self Certification Review B2B", "ReturnDocType": "DOC", "case_status": 'live', "b2b": true, 'group': "Self Certification" },
            { "name": "Self Certification Submission", "type": "link_case", "resource": "/form/49", "case_status": 'live', "b2b": true, 'group': "Self Certification"   },
            { "name": "Self Certification Submission", "type": "link_case", "resource": "/form/49", "case_status": 'live', "b2b": false, 'group': "Self Certification"   },
            // { "name": "Risk Matrix Variation Sub", "type": "link_case", "resource": "/form/47", "case_status": 'live', "b2b": true  },
            // { "name": "Risk Matrix Variation Sub", "type": "link_case", "resource": "/form/46", "case_status": 'live', "b2b": false  },


            //Dead
            { "name": "Revert to Prospect", "type": "link_dead", "resource": "/form/41", "case_status": 'dead', "b2b": true  },
            { "name": "Revert to Prospect", "type": "link_dead", "resource": "/form/41", "case_status": 'dead', "b2b": false  },

            //Recovery
            { "name": "Data Review", "type": "link_recovery", "resource": "/form/40", "case_status": 'recovery', "b2b": true  },
            { "name": "Data Review", "type": "link_recovery", "resource": "/form/39", "case_status": 'recovery', "b2b": false  },
            { "name": "Risk Matrix Input", "type": "link_recovery", "resource": "/form/27", "case_status": 'recovery', "b2b": true  },
            { "name": "Risk Matrix Input", "type": "link_recovery", "resource": "/form/28", "case_status": 'recovery', "b2b": false  },
            { "name": "Risk Matrix", "type": "pdf_recovery", "resource": "Risk Matrix Portal", "case_status": 'recovery'  },
            { "name": "Drawdown Request Form", "type": "pdf_recovery", "resource": "Drawdown Request Form", "ReturnDocType": "DOC", "case_status": 'recovery'  },
            { "name": "Review Paper", "type": "pdf_recovery", "resource": "Review Paper", "ReturnDocType": "DOC", "case_status": 'recovery', "b2b": false },
            { "name": "Review Paper", "type": "pdf_recovery", "resource": "Review Paper - Business", "ReturnDocType": "DOC", "case_status": 'recovery', "b2b": true },

            //Workout
            { "name": "Data Review", "type": "link_workout", "resource": "/form/40", "case_status": 'workout', "b2b": true  },
            { "name": "Data Review", "type": "link_workout", "resource": "/form/39", "case_status": 'workout', "b2b": false  },
            { "name": "Risk Matrix Input", "type": "link_workout", "resource": "/form/27", "case_status": 'workout', "b2b": true  },
            { "name": "Risk Matrix Input", "type": "link_workout", "resource": "/form/28", "case_status": 'workout', "b2b": false  },
            { "name": "Risk Matrix", "type": "pdf_workout", "resource": "Risk Matrix Portal", "case_status": 'workout'  },
            { "name": "Drawdown Request Form", "type": "pdf_workout", "resource": "Drawdown Request Form", "ReturnDocType": "DOC", "case_status": 'workout'  },
            { "name": "Review Paper", "type": "pdf_workout", "resource": "Review Paper", "ReturnDocType": "DOC", "case_status": 'workout', "b2b": false },
            { "name": "Review Paper", "type": "pdf_workout", "resource": "Review Paper - Business", "ReturnDocType": "DOC", "case_status": 'workout', "b2b": true },

            //Repaid
            //Should Stay Blank

            // Prospects
            { "name": "Introducer Input", "type": "link_prospect", "resource": "/form/32"  },
            { "name": "Lead Input", "type": "link_prospect", "resource": "/form/36"  },
            { "name": "Convert to Prospect", "type": "link_prospect", "resource": "/form/30"  },
            { "name": "Not Proceeding", "type": "link_prospect", "resource": "/form/31"  },
        ];
    }

    if(domain==='portal.digitusrain.co.uk') {
        base_url = 'https://test.myrewardfinance.co.uk/Webservices/BrightOfficeAPI.asmx/';
        logo = '/images/logo-reward-finance.svg';
        visible_articles = false;
        visible_quick_quote_btn = false;
        cleanUpNames = ['calvin.dexter', 'damien.slingsby', 'dominic.sievewright', 'ian.lewis', 'james.mortimore', 'neil.mcgivern', 'nick.battersby', 'noel.haverly', 'peter.cromarty', 'rob.mercer'];
        assessor_questions = [44,45,46,47,48,49,50,51,52,53,54,55,56,57];
        forms = [
            // Cases
            // { "name": "Heads of Terms Input", "type": "link_case", "resource": "/form/8"   },
            { "name": "Heads of Terms Input", "type": "link_case", "resource": "/form/14", "case_status": 'prospect', "b2b": true  },
            { "name": "Heads of Terms", "type": "pdf_case", "resource": "Heads of Terms", "case_status": 'prospect', "b2b": true   },
            { "name": "Heads of Terms Input", "type": "link_case", "resource": "/form/18", "case_status": 'prospect', "b2b": false },
            { "name": "Heads of Terms", "type": "pdf_case", "resource": "Heads of Terms Ind", "case_status": 'prospect', "b2b": false  },
            { "name": "Proposal Form", "type": "docx_case", "resource": "/clientworddocs/rewardfinance/Proposal Form.pdf", "case_status": 'prospect'  },
            { "name": "Proposal Form Input", "type": "link_case", "resource": "/form/37", "case_status": 'prospect', "b2b": true   },
            { "name": "Proposal Form Input", "type": "link_case", "resource": "/form/38", "case_status": 'prospect', "b2b": false  },
            { "name": "Asset and Liability Statement", "type": "docx_case", "resource": "/clientworddocs/rewardfinance/Statement of Personal Assets and Liabilities.pdf", "case_status": 'prospect'  }, 
            { "name": "Etridge Letter", "type": "docx_case", "resource": "/clientworddocs/rewardfinance/Etridge Letter to 3P Obligor.docx", "case_status": 'prospect'  },
            { "name": "Consent Letter", "type": "docx_case", "resource": "/clientworddocs/rewardfinance/Letter of Consent (property).doc", "case_status": 'prospect'  },
            { "name": "Risk Matrix Input", "type": "link_case", "resource": "/form/9", "case_status": 'prospect', "b2b": true  },
            { "name": "Risk Matrix Input", "type": "link_case", "resource": "/form/20", "case_status": 'prospect', "b2b": false  },
            { "name": "Risk Matrix", "type": "pdf_case", "resource": "Risk Matrix Portal", "case_status": 'prospect'  },
            { "name": "Credit Paper Input", "type": "link_case", "resource": "/form/10", "case_status": 'prospect', "b2b": true  },
            { "name": "Credit Paper Input", "type": "link_case", "resource": "/form/21", "case_status": 'prospect', "b2b": false  },
            { "name": "Credit Paper", "type": "pdf_case", "resource": "Credit Paper", "ReturnDocType": "DOC", "case_status": 'prospect', "b2b": false  },
            { "name": "Credit Paper", "type": "pdf_case", "resource": "Credit Paper -Business", "ReturnDocType": "DOC", "case_status": 'prospect', "b2b": true  },
            { "name": "Drawdown Request Form", "type": "pdf_case", "resource": "Drawdown Request Form", "ReturnDocType": "DOC", "case_status": 'prospect'  },
            { "name": "Deal Dead", "type": "link_case", "resource": "/form/15", "case_status": 'prospect', "b2b": false  },
            { "name": "Deal Dead", "type": "link_case", "resource": "/form/23", "case_status": 'prospect', "b2b": true  },
            { "name": "Data Review", "type": "link_case", "resource": "/form/39", "case_status": 'prospect', "b2b": false    },
            { "name": "Data Review", "type": "link_case", "resource": "/form/40", "case_status": 'prospect', "b2b": true  },
            { "name": "Deal Complete", "type": "link_case", "resource": "/form/13", "case_status": 'prospect', "b2b": false    },
            { "name": "Deal Complete", "type": "link_case", "resource": "/form/22", "case_status": 'prospect', "b2b": true  },
            // { "name": "Review Paper", "type": "pdf_case", "resource": "Review Paper", "ReturnDocType": "DOC", "case_status": 'prospect' },
            // { "name": "Miscellaneous Input", "type": "link_case", "resource": "/form/33", "case_status": 'prospect', "b2b": true   },
            // { "name": "Miscellaneous Input", "type": "link_case", "resource": "/form/33", "case_status": 'prospect', "b2b": false  },
            // { "name": "Drawdown Request Form", "type": "docx_case", "resource": "/clientworddocs/rewardfinance/Capital - Drawdown Request Form.doc"  },
            //Live Cases
            { "name": "Data Review", "type": "link_case", "resource": "/form/40", "case_status": 'live', "b2b": true  },
            { "name": "Data Review", "type": "link_case", "resource": "/form/39", "case_status": 'live', "b2b": false  },
            { "name": "Risk Matrix Input", "type": "link_case", "resource": "/form/27", "case_status": 'live', "b2b": true  },
            { "name": "Risk Matrix Input", "type": "link_case", "resource": "/form/28", "case_status": 'live', "b2b": false  },
            { "name": "Risk Matrix", "type": "pdf_case", "resource": "Risk Matrix Portal", "case_status": 'live'  },
            { "name": "Drawdown Request Form", "type": "pdf_case", "resource": "Drawdown Request Form", "ReturnDocType": "DOC", "case_status": 'live'  },
            // { "name": "Review Paper", "type": "pdf_case", "resource": "Review Paper", "ReturnDocType": "DOC", "case_status": 'live', "b2b": false },
            // { "name": "Review Paper", "type": "pdf_case", "resource": "Review Paper - Business", "ReturnDocType": "DOC", "case_status": 'live', "b2b": true },
            //Review Variation Section in Live cases
            { "name": "Variations Input", "type": "link_case", "resource": "/form/42", "case_status": 'live', "b2b": true  },
            { "name": "Variations Input", "type": "link_case", "resource": "/form/42", "case_status": 'live', "b2b": false  },
            { "name": "Variations Paper", "type": "pdf_case", "resource": "Review Paper - Business New", "ReturnDocType": "DOC", "case_status": 'live', "b2b": false  },
            { "name": "Variations Paper", "type": "pdf_case", "resource": "Review Paper - Business New", "ReturnDocType": "DOC", "case_status": 'live', "b2b": true  },
            { "name": "Risk Matrix Input", "type": "link_case", "resource": "/form/44", "case_status": 'live', "b2b": true  },
            { "name": "Risk Matrix Input", "type": "link_case", "resource": "/form/45", "case_status": 'live', "b2b": false  },
            { "name": "Risk Matrix", "type": "pdf_case", "resource": "Risk Matrix Review", "case_status": 'live', "b2b": false  },
            { "name": "Risk Matrix", "type": "pdf_case", "resource": "Risk Matrix Review", "case_status": 'live', "b2b": true  },
            { "name": "Variations Submission", "type": "link_case", "resource": "/form/43", "case_status": 'live', "b2b": true  },
            { "name": "Variations Submission", "type": "link_case", "resource": "/form/43", "case_status": 'live', "b2b": false  },
            // { "name": "Risk Matrix Variation Sub", "type": "link_case", "resource": "/form/47", "case_status": 'live', "b2b": true  },
            // { "name": "Risk Matrix Variation Sub", "type": "link_case", "resource": "/form/46", "case_status": 'live', "b2b": false  },


            //Dead
            { "name": "Revert to Prospect", "type": "link_dead", "resource": "/form/41", "case_status": 'dead', "b2b": true  },
            { "name": "Revert to Prospect", "type": "link_dead", "resource": "/form/41", "case_status": 'dead', "b2b": false  },

            //Recovery
            { "name": "Data Review", "type": "link_recovery", "resource": "/form/40", "case_status": 'recovery', "b2b": true  },
            { "name": "Data Review", "type": "link_recovery", "resource": "/form/39", "case_status": 'recovery', "b2b": false  },
            { "name": "Risk Matrix Input", "type": "link_recovery", "resource": "/form/27", "case_status": 'recovery', "b2b": true  },
            { "name": "Risk Matrix Input", "type": "link_recovery", "resource": "/form/28", "case_status": 'recovery', "b2b": false  },
            { "name": "Risk Matrix", "type": "pdf_recovery", "resource": "Risk Matrix Portal", "case_status": 'recovery'  },
            { "name": "Drawdown Request Form", "type": "pdf_recovery", "resource": "Drawdown Request Form", "ReturnDocType": "DOC", "case_status": 'recovery'  },
            { "name": "Review Paper", "type": "pdf_recovery", "resource": "Review Paper", "ReturnDocType": "DOC", "case_status": 'recovery', "b2b": false },
            { "name": "Review Paper", "type": "pdf_recovery", "resource": "Review Paper - Business", "ReturnDocType": "DOC", "case_status": 'recovery', "b2b": true },

            //Workout
            { "name": "Data Review", "type": "link_workout", "resource": "/form/40", "case_status": 'workout', "b2b": true  },
            { "name": "Data Review", "type": "link_workout", "resource": "/form/39", "case_status": 'workout', "b2b": false  },
            { "name": "Risk Matrix Input", "type": "link_workout", "resource": "/form/27", "case_status": 'workout', "b2b": true  },
            { "name": "Risk Matrix Input", "type": "link_workout", "resource": "/form/28", "case_status": 'workout', "b2b": false  },
            { "name": "Risk Matrix", "type": "pdf_workout", "resource": "Risk Matrix Portal", "case_status": 'workout'  },
            { "name": "Drawdown Request Form", "type": "pdf_workout", "resource": "Drawdown Request Form", "ReturnDocType": "DOC", "case_status": 'workout'  },
            { "name": "Review Paper", "type": "pdf_workout", "resource": "Review Paper", "ReturnDocType": "DOC", "case_status": 'workout', "b2b": false },
            { "name": "Review Paper", "type": "pdf_workout", "resource": "Review Paper - Business", "ReturnDocType": "DOC", "case_status": 'workout', "b2b": true },

            //Repaid
            //Should Stay Blank

            // Prospects
            { "name": "Introducer Input", "type": "link_prospect", "resource": "/form/32"  },
            { "name": "Lead Input", "type": "link_prospect", "resource": "/form/36"  },
            { "name": "Convert to Prospect", "type": "link_prospect", "resource": "/form/30"  },
            { "name": "Not Proceeding", "type": "link_prospect", "resource": "/form/31"  },
        ];
    }

    // if(domain==='portal.digitusrain.co.uk') {
    //     base_url = 'https://brokercrm.co.uk/Webservices/BrightOfficeAPI.asmx/';
    //     logo = '/images/logo-brightOffice.png';
    //     visible_articles = false;
    //     visible_quick_quote_btn = false;
    //     forms = [
    //         // Cases
    //         // { "name": "Heads of Terms Input", "type": "link_case", "resource": "/form/8"   },
    //         { "name": "Make a Booking", "type": "link_case", "resource": "/form/14", "b2b": true  },
    //         { "name": "Print Certificate", "type": "pdf_case", "resource": "Heads of Terms", "b2b": true   },
    //         // { "name": "Drawdown Request Form", "type": "docx_case", "resource": "/clientworddocs/rewardfinance/Capital - Drawdown Request Form.doc"  },
    //         // Prospects
    //         { "name": "Heads of Terms Input", "type": "link_prospect", "resource": "/form/14"  },
    //         { "name": "Proposal Form Input", "type": "link_prospect", "resource": "/form/7"  },
    //     ];
    // }

    if(domain==='crm.evelocity.info') {
        logo = '/images/logo-reward-finance.svg';
    }

    if(domain==='localhost') {
        cleanUpNames = [];
    }

    // if local storage is empty we are probably on the login page
    if(localStorage.getItem('bo_email')===null) {
        let data = {
            'nav_links': [],
            'base_url': base_url,
            'logo': logo
        }
        return data;
    }


    return {
        'base_url': base_url,
        'forms': forms,
        'visible_articles': visible_articles,
        'visible_quick_quote_btn': visible_quick_quote_btn,
        'logo': logo,
        'navbarClass': 'white', // dark or white
        'navIconClass': 'dark', // dark or white
        'personName': [localStorage.getItem("bo_user_first_name") ?? '', localStorage.getItem("bo_user_surname") ?? ''].join(' '),
        'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
        'personFunction': 'Portal User',
        'personProfileImage': '/images/profile.png',
        'customerLimitCode': 'C000696', // unused?
        'client_id': 1,
        'cleanUpNames': cleanUpNames,
        'nav_links': nav_links,
        'assessor_questions': assessor_questions
    }




    /*
    if( localStorage.getItem('bo_email') === 'srdjan.stojkovicapi' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo.png',
            'navbarClass': 'dark', // dark or white
            'navIconClass': 'white', // dark or white
            'personName': 'Ollie Roby',
            'custom_css': './css/peregrine/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/profile.png',
            'customerLimitCode': 'C000696',
            'client_id': 1,
            'cleanUpNames': cleanUpNames,
            'nav_links': [
                ['/form/3', 'Form number 3'],
                ['/form/4', 'Form number 4'],
            ],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'broker.api' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-brightOffice.png',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Ollie Roby',
            'custom_css': './css/domain/portal.mymintbridging.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/profile.png',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [
                ['/form/11', 'Prospect Creation'],
                ['/form/8', 'Heads of Terms'],
                ['/form/7', 'Proposal Form'],
                ['/form/9', 'Risk Score'],
                ['/form/10', 'Credit Form'],
            ],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'mike.newhouse' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Mike Newhouse',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'cleanUpNames': cleanUpNames,
            'client_id': 2,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'tom.newhouse' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Tom Newhouse',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'simon.adcock' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Simon Adcock',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'becky.hayward' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Becky Hayward',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'lisa.cooper' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Lisa Cooper',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'guillermo.coria' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Guillermo Coria',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'harriet.gibbs' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Harriet Gibbs',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'adam.dar' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Adam Dar',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'steph.brown' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Steph Brown',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'ed.beecher' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Ed Beecher',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'mark.swindell' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Mark Swindell',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'reward.test' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'reward.test',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'portaltom.newhouse' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Tom Newhouse',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'portalchristine.daly' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Christine Daly',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'portalcassey.mcnulty' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Cassey McNulty',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'portalpaula.jones' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Paula Jones',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'portallouise.donnelly' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Louise Donnelly',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'portalluke.drysdale' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Luke Drysdale',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'portalbethany.hunnebell' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Bethany Hunnebell',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'portalclaire.mitchell' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Claire Mitchell',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'portalchris.ibbetson' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Chris Ibbetson',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'portaljohn.gallagher' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'John Gallagher',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'portaltim.stafford' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Tim Stafford',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'portaldavid.baddiel' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'David Baddiel',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'portalalan.sanderson' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Alan Sanderson',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'portaljoanna.haigh' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Joanna Haigh',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'portalkaren.vincent' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Karen Vincent',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'portalrachel.lee' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Rachel Lee',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'portalgemma.wright' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Gemma Wright',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'portalmike.newhouse' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Mike Newhouse',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'will.debney' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Will Debney',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'portalnick.smith' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Nick Smith',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'portalkaren.paige' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Karen Paige',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'portalnick.battersby' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Nick Battersby',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'portalsteve.noble' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Steve Noble',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'portaltom.flannery' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Tom Flannery',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'portaldavid.jones' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'David Jones',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'portalclive.briggs' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Clive Briggs',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'portalmike.calvert' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Mike Calvert',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'portalrussell.holt' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Russell Holt',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'portalelizabeth.harrison' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Elizabeth Harrison',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'portalparisa.lian' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Parisa Lian',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'portaldavid.harrop' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'David Harrop',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'portalsimon.micklethwaite' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Simon Micklethwaite',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'christine.daly' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Christine Daly',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'cassey.mcnulty' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Cassey McNulty',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
if( localStorage.getItem('bo_email') === 'adam.lunn' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Adam Lunn',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'katie.myrtle' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': ' Katie Myrtle',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'paula.jones' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Paula Jones',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'louise.donnelly' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Louise Donnelly',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'ella.naylor' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Ella Naylor',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'paula.robertson' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Paula Robertson',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'brian.machray' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Brian Machray',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'luke.drysdale' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Luke Drysdale',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'bethany.hunnebell' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Bethany Hunnebell',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'claire.mitchell' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Claire Mitchell',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'chris.ibbetson' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Chris Ibbetson',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'john.gallagher' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'John Gallagher',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'tim.stafford' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Tim Stafford',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'david.baddiel' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'David Baddiel',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'alan.sanderson' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Alan Sanderson',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'bryce.dawson' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Bryce Dawson',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'joanna.haigh' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Joanna Haigh',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'karen.vincent' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Karen Vincent',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'rachel.lee' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Rachel Lee',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'gemma.wright' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Gemma Wright',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'nick.smith' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Nick Smith',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'karen.paige' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Karen Paige',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'nick.battersby' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Nick Battersby',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'steve.noble' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Steve Noble',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'tom.flannery' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Tom Flannery',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'david.jones' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'David Jones',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'clive.briggs' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Clive Briggs',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'mike.calvert' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Mike Calvert',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'russell.holt' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Russell Holt',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'rick.jones' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Rick Jones',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'elizabeth.harrison' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Elizabeth Harrison',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'parisa.lian' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Parisa Lian',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'david.harrop' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'David Harrop',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'simon.micklethwaite' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Simon Micklethwaite',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'reward.finance' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-cooperreed.png',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Ollie Roby',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/profile.png',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [
                ['/form/11', 'Prospect Creation'],
                ['/form/8', 'Heads of Terms'],
                ['/form/7', 'Proposal Form'],
                ['/form/9', 'Risk Score'],
                ['/form/10', 'Credit Form'],
            ],
            'assessor_questions': assessor_questions
        }
    }
    */
    alertify.confirm('Error', "No config for this user", null, null);
}

